import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { SearchCompanyOutputApiModel, InfoCompanyOutputApiModel, CampaignOutputApiModel, WorkAttributeOutputApiModel, LandingPageOutputApiModel } from '../models/api-models/output';
import { CreateCompanyInputApiModel, CreateCampaignInputApiModel, GetCampaignInputApiModel, UpdateCampaignInputApiModel, UpdateCompanyInputApiModel, GetLandingPageInputApiModel, UpdateLandingPageInputApiModel, CreateLandingPageInputApiModel } from '../models/api-models/input';
import { ResponsePaginationApiModel } from '@framework/models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class StoryFeedEmbedService extends BaseHttpService {
    
    public campaignQueryData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public featuredQueryData$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(httpClient: HttpClient) {
        super(httpClient, '/stories');
    }

    
    public getcompanyEmbedFedd(type,Id: number | string) {
        //console.log("errOuter>>")
        if(type == 'company'){
            //console.log("err>>")
            return this.get(`/embed/storyfeedembed/?id_company=${Id}&timestamp: ${Date.now().toString()}`).pipe(
                map((response) => {
                    //console.log("errInside>>")
                    return response;
                })
            )
        }
        else if(type == 'campaign'){
            //console.log("err>>")
            return this.get(`/embed/storyfeedembed/?id_campaign=${Id}&timestamp: ${Date.now().toString()}`).pipe(
                map((response) => {
                    //console.log("errInside>>")
                    return response;
                })
            )
        }
        else{
            return this.get(`/embed/storyfeedembed/?id_spotlight=${Id}&timestamp: ${Date.now().toString()}`).pipe(
                map((response) => {
                    //console.log("errInside>>")
                    return response;
                })
            )
        }

    }

    public postPatchcompanyEmbedFeed(payload:any,type:any) {
        if(type == 'POST'){
            return this.post(`/embed/storyfeedembed/`,payload).pipe(
                map((response) => {
                    return response;
                })
            )
        }
        else{
            return this.patch(`/embed/storyfeedembed/`,payload).pipe(
                map((response) => {
                    return response;
                })
            )
        }

    }
}
