import { Component, ViewEncapsulation, Inject, ChangeDetectorRef, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@app/env/environment';
import { debounceTime } from 'rxjs/operators';
import { RssFeedHttpService } from '@modules/company/services/rss-feed.http-service';
import { UserProfileViewModel, UserViewModel } from '@modules/user/store/models';
import { CampaignViewModel, CompanyViewModel } from '@modules/company/models/store-view-models';
import { StoryOutputApiModel } from '@modules/story/models/api-models/output';
import { CoreState } from '@app/store/core/states';
import { ScrollbarComponent } from '@framework/components';
import { PaginationModel } from '@framework/models/pagination-models';
import { ToastService } from '@framework/services';
import { UtilityHelper } from '@framework/utilities';
import { campaignActions, storyActions } from '@modules/company-admin/store/actions';
import { GetCampaignInputApiModel } from '@modules/company/models/api-models/input';
import { companySelectors, campaignSelectors } from '@modules/company/store/selectors';
import { RequestInputApiModel } from '@modules/shared/models/api-models/input';
import { CommentHttpService } from '@modules/shared/services';
import { storyAction, storyStepActions } from '@modules/story/store/actions';
import { LoadUserInputApiModel } from '@modules/user/models/input';
import { ValidateUserOutApiModel } from '@modules/user/models/output';
import { userActions, userPermissionActions } from '@modules/user/store/actions';
import { userSelectors, userProfileSelectors } from '@modules/user/store/selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isString } from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'request-story-campaign-modal',
  templateUrl: './request-story-campaign-modal.component.html',
  styleUrls: ['./request-story-campaign-modal.component.scss'],
  animations: [
    trigger('showhide', [
      // ...
      state('show', style({
        opacity: 1,
        zIndex: 1
      })),
      state('hide', style({
        opacity: 0,
        zIndex: -1
      })),
      transition('show => hide', [
        animate('0.5s ease-in-out')
      ]),
      transition('hide => show', [
        animate('0.5s ease-in-out')
      ]),
    ]),
    trigger('showhideadthis', [
      // ...
      state('show', style({
        opacity: 1,
        zIndex: 1000
      })),
      state('hide', style({
        opacity: 0,
        zIndex: -1
      })),
      transition('show => hide', [
        animate('0.5s ease-in-out')
      ]),
      transition('hide => show', [
        animate('0.5s ease-in-out')
      ]),
    ]),
  ]
})

export class RequestStoryCampaignContainerComponent extends ComponentModalBase<any> {
  @ViewChild("shareholic") shareholic: ElementRef;
  private getMembers$: Observable<UserViewModel[]>;
  private getNextUrl$: Observable<string>;
  private selectPlayerLoading$: Observable<boolean>;
  private selectPlayerLoaded$: Observable<boolean>;
  private selectCurrentCompany$: Observable<CompanyViewModel>;
  private selectCurrentProfile$: Observable<UserProfileViewModel>;
  public upatedCampaignList:any;
  public showRequestedUser:boolean = false;

  private formChangeSubscription: Subscription;
  private inviteFormchange: Subscription;
  private getNextUrlSubscription: Subscription;
  private selectPlayerLoadingSubscription: Subscription;
  private selectPlayerLoadedSubscription: Subscription;
  private getMembersSubscription: Subscription;
  private selectCurrentCompanySubscription: Subscription;
  private selectCurrentProfileSubscription: Subscription;
  private validateUserSuccessSubscription: Subscription;
  private selcetFormSubscription: Subscription;
  private loadQuestionsSubscription: Subscription;
private loadColorSuccessSubscription: Subscription;
  private loadUserInputData: LoadUserInputApiModel = new LoadUserInputApiModel(new PaginationModel(1000));
  private isPlayerAdding: boolean = false;
  public members: any = [];
  public newMembers: any = [];
  public camapignqueston: any[] = [];
  public requestedMembers: any[] = [];
  public username:any;
  public loggedInUserId:any;
  public questionList:any[] = [];
  public searchBarVisible: boolean = false;
  public requestedUsers: any[] = []
  public isCopyCode: boolean = false
  public readOnlyforTextfiled: boolean = false;
  public isShowPromptContainer: boolean = false;
  public buttonText: string = 'Copy Link'
  public userQuestion: any[] = [];
  public guestquestion: any[] = [];
  public isdisplay: boolean = false;
  private loadPermissionSuccessSubscription: Subscription;
  public isSingleInternalRequested: boolean = false;
  public externalUrlforCopy:string;
  public hideSendanCopylinkButton: boolean = false;
  public InternalStorySingleUrl:string;
  public emailSendIngPayload: any [] = [];
  public viewmodel: any = {
    scrollbarVisible: false,
    reachStarted: false,
    reachEnded: false,
    firstLoad: true,
    lastPosition: null,
    fetch: false,
    searching: false,
    isStoryCreating: false,
    isStoryUpdating: false,
    isPlayerCreating: false,
    isPlayerLoading: false,
    isPlayerLoaded: false,
    isInboundCampaign: false,
    //loadingDataOnScroll: false
  };
  public form: UntypedFormGroup;
  public addPlayerForm: UntypedFormGroup;
  public requestForm: UntypedFormGroup;
  public loadMore: boolean = true;
  public stepActive: any;
  public selectedData: any = [];
  public storyData: StoryOutputApiModel;
  public isFormSubmited: boolean = false;
  public companyLogo: string;
  public company: CompanyViewModel;
  public isNoOneSpecific: boolean = false;
  public diableonsearching: boolean = false;
  public isShowNoOneSpecific: boolean = false;
  public isHideErrorEmailInput: boolean = true;
  public isLoading: boolean = false;
  public campaigns: CampaignViewModel[] = [];
  public questionModels:any[] = [];
  private selectCampaign$: Observable<CampaignViewModel[]>;
  private selectCampaignSubscription: Subscription;
  public isDispalyMenu: boolean = false
  public primary_color:any;
  public isDispalyadthis: boolean = false
  private queryCampaignsData: GetCampaignInputApiModel = new GetCampaignInputApiModel(new PaginationModel(1000));

  @ViewChild('scrollbar')
  scrollbar: ScrollbarComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store$: Store<CoreState>,
    private detector: ChangeDetectorRef,
    private actions$: Actions,
    private utility: UtilityHelper,
    private router: Router,
    private actions: Actions,
    private toastService: ToastService,
    private requestService: CommentHttpService
  ) {
    super();

    this.getMembers$ = this.store$.select(userSelectors.selectUsers);
    this.getNextUrl$ = this.store$.select(userSelectors.selectNextUrl);
    this.selectPlayerLoading$ = this.store$.select(userSelectors.selectLoading);
    this.selectPlayerLoaded$ = this.store$.select(userSelectors.selectLoaded);
    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectCurrentProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.selectCampaign$ = this.store$.select(campaignSelectors.selectAll);

    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      keyword: ['']
    });

    this.requestForm = this.formBuilder.group({
      about: [''],
      bodyCode: [{ value: '', disabled: true }],
      content:[{value: '', disabled: true}],
      campaign: [-1],
      note_email:[],
      requestedUser: [''],
      userselcted:[],
      externalUserSelcted:[],
      question:[-1],
      userStatus:['user'],
      question_id_campaign:[-1],
      addPersonalize:[false],
      firstname:[''],
      lastname:[''],
      message:[''],
      internalmessage:[''],
      greetings:[''],
      email:[]
    });


    this.addPlayerForm = this.formBuilder.group({
      players: this.formBuilder.array([this.createAddPlayerItemForm()])
    });
  }

  private createAddPlayerItemForm() {
    return this.formBuilder.group({
      firstName: ['', Validators.required],
      email: ['', Validators.compose([this.emailValidator.bind(this), Validators.email])]
    });
  }

  protected onInit(): void {
    this.subscribe();
    //console.log(">>",this.initialState)
  }
  protected onDestroy(): void {
    this.unSubscribe();
  }

  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
    // $(document).on('click','#cancel-request-btn',function(event){
    //   close()
    // })
  }

  protected onAfterViewChecked(): void {

  }

  private subscribe() {
    this.inviteFormchange = this.requestForm.controls['message'].valueChanges.pipe(
      debounceTime(100)
    ).subscribe((value) => {
  
      if(this.hideSendanCopylinkButton && this.isSingleInternalRequested){
        this.hideSendanCopylinkButton  = false;
        this.isSingleInternalRequested = false;
      }
  
    })
    this.formChangeSubscription = this.form.controls['keyword'].valueChanges.pipe(
      debounceTime(900)
    ).subscribe((value) => {
      this.viewmodel.searching = true;
      this.loadUserInputData.search = value && value.trim() || '';
      this.loadUserInputData.pagination.page = 1;
      this.loadUserInputData.pagination.sort = 'desc'

      // setTimeout(()=>{
      //   this.scrollbar.scrollToTop(0);
      // },1000)

      if (this.loadUserInputData.search.length === 0) {
        this.diableonsearching = false;
        this.resetSearchForm();
      } else {
        this.diableonsearching = true;
        this.loadMembers([]);
      }
    });

    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.GetColorSuccess>(storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.primary_color = action.response.find(item => item.button == 11).color;
       }
      

    })

    this.loadPermissionSuccessSubscription =  this.actions$.pipe(
      ofType<userPermissionActions.LoadPermissionsSuccess>(userPermissionActions.ActionTypes.LOAD_PERMISSION_SUCCESS)
    ).subscribe((action: any) => {
     if(action.response && action.response.length){
      this.showRequestedUser = action.response.find(permission => permission.name === 'requested_user_details');
     }
    })

    this.selectCampaignSubscription = this.selectCampaign$.subscribe((campaigns) => {
      if (campaigns && (!this.campaigns.length || this.campaigns.length === 1)) {
        this.campaigns = campaigns.filter(camp => camp.daysLeft > 0) || [];
        const nonCampaign = {
          id: -1,
          storyPrompt: 'Select option',
          campaign_id: -1,
          position: 0
        };

         
        for(let i=0 ; i< this.campaigns.length ; i++){

          if(Array.isArray(this.campaigns[i].storyPrompt)){
            this.campaigns[i].storyPrompt = [...this.campaigns[i].storyPrompt.filter(item => item.is_stories)];
            let storyprompts = this.campaigns[i].storyPrompt /*.sort((a,b)=>{return a.order_campaign_question - b.order_campaign_question})*/
            for(let j=0; j < storyprompts.length ; j++ ){
              let obj = {
                 storyPrompt: storyprompts[j].story_prompt,
                 campaign_id:  this.campaigns[i].id,
                 id:+storyprompts[j].id,
                 position: this.questionModels.length
              }
              this.questionModels.push(obj);
           }
          }
    
        }

      }
    });

    this.getNextUrlSubscription = this.getNextUrl$.subscribe((nextUrl) => {
      this.loadMore = nextUrl !== null;
    });

    this.selectPlayerLoadingSubscription = this.selectPlayerLoading$.subscribe((isLoading: boolean) => {
      this.viewmodel.isPlayerLoading = isLoading;
    });

    this.selectPlayerLoadedSubscription = this.selectPlayerLoaded$.subscribe((isLoaded: boolean) => {
      this.viewmodel.isPlayerLoaded = isLoaded;
    });

    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((company) => {
      if (company) {
        this.companyLogo = company.logo;
        this.company = company;
        this.store$.dispatch(new storyActions.GetColor(this.company.id));
      }
    });

    this.getMembersSubscription = this.getMembers$.subscribe((members) => {
      this.viewmodel.searching = false;
     // this.viewmodel.loadingDataOnScroll = false;
      this.detector.detectChanges();
      this.viewmodel.isInboundCampaign = (this.viewmodel.inbound && this.company.isCampaignCompany);
      if (!this.viewmodel.isInboundCampaign) {
         this.members = members;
         this.requestedMembers = this.members.map((member)=>{
          return {
            title:member.displayName,
            id:member.id
          }
         })
         if(this.requestedMembers.length && this.loggedInUserId){
          let loggedIn ={
            id:this.loggedInUserId,
            title:this.username
          }
          this.requestedMembers.unshift(loggedIn)
          if(this.requestForm.value.userStatus == 'Guestuser'  &&  !this.isSingleInternalRequested){
            this.requestForm.controls.externalUserSelcted.setValue(this.loggedInUserId)
          }

          else if( this.requestForm.value.userStatus == 'user' && !this.isCopyCode){
            this.requestForm.controls.userselcted.setValue(this.loggedInUserId)
          }
        }
  
      }
      this.updateSelectedValues();
    });

    this.selectCurrentProfileSubscription = this.selectCurrentProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.companyLogo = userProfile.company.logo;
        this.viewmodel.inbound = false;
        this.loggedInUserId = userProfile.id
        this.username = userProfile.displayName
        this.viewmodel.companyId = userProfile.company.id;
        this.selectedData = [];
        this.playersFormArray.controls.forEach((fgItem: UntypedFormGroup) => {
          fgItem.controls.email.updateValueAndValidity();
        });
        this.store$.dispatch(new storyStepActions.LoadQuestions(userProfile.company.id))
        this.updateSelectedValues();
        this.updateSelectedNoOneSpecific();
        this.loadMembers(this.selectedData);
        this.queryCampaignsData.isLoadExpired = false;
        this.queryCampaignsData.pagination.sort = 'desc';
        this.queryCampaignsData.isIncludeStories = true;
        this.queryCampaignsData.public = false;
        this.queryCampaignsData.pagination.limit = 100;
        this.store$.dispatch(new userPermissionActions.LoadPermissions())
      }
    });

    this.validateUserSuccessSubscription = this.actions.pipe(
      ofType<userActions.ValidateUserSuccess>(userActions.ActionTypes.VALIDATE_USER_SUCCESS)
    ).subscribe((action: userActions.ValidateUserSuccess) => {
      this.validateUsersSuccess(action.response);
    });
    this.loadQuestionsSubscription = this.actions$.pipe(
      ofType<storyStepActions.LoadQuestionsSuccess>(storyStepActions.ActionTypes.LOAD_QUESTIONS_SUCCESS)).
        subscribe((questions: any) => {
         if(questions && questions.data && questions.data && questions.data.length){
          this.questionList= questions.data.filter(question => question.is_active == 1);
          if(this.questionList.length){
            const nonQuestion = {
              id:-1,
              is_active:null,
              question_text: 'Select option'

            }
            this.userQuestion = this.questionList.filter(item => item.inbound == false);
            this.guestquestion = this.questionList.filter(item => item.inbound == true);
            this.userQuestion.unshift(nonQuestion);
            this.guestquestion.unshift(nonQuestion)

          }

         }
      })

    this.selcetFormSubscription = this.requestForm.controls.requestedUser.valueChanges.subscribe((data: any) => {
      this.requestForm.controls.bodyCode.setValue(data)
    })

  }

  showSearchBar(): void {
    this.searchBarVisible = true;
    setTimeout(() => {
      document.getElementById("searchUnsplash").focus();
    }, 100);
  }

  private unSubscribe() {
    this.formChangeSubscription.unsubscribe();
    this.getNextUrlSubscription.unsubscribe();
    this.selectPlayerLoadingSubscription.unsubscribe();
    this.selectPlayerLoadedSubscription.unsubscribe();
    this.getMembersSubscription.unsubscribe();
    this.selectCurrentCompanySubscription.unsubscribe();
    this.selectCurrentProfileSubscription.unsubscribe();
    this.validateUserSuccessSubscription.unsubscribe();
    this.selcetFormSubscription.unsubscribe();
    this.loadPermissionSuccessSubscription.unsubscribe();
    this.loadColorSuccessSubscription.unsubscribe();
  }

  private loadMembers(selectedData: any[]) {
    this.loadUserInputData.inbound = this.viewmodel.inbound;
    this.loadUserInputData.companyId = this.viewmodel.companyId;
    this.loadUserInputData.pagination.sort = 'desc'
    selectedData = selectedData || [];
    this.loadUserInputData.selectedValues = selectedData.filter(id => this.utility.Lodash.isNumber(id) && id > 0);
    let selectedEmails: string[] = [];
    const idsAsString = selectedData.filter(id => this.utility.Lodash.isString(id));
    idsAsString.forEach((sData: string) => {
      const emailParserRes = this.utility.StringHelper.emailParser(sData);
      selectedEmails.push(emailParserRes.email);
    });
    this.loadUserInputData.selectedEmails = selectedEmails;
    this.store$.dispatch(new userActions.Load(this.loadUserInputData));
  }

  private updateSelectedValues() {
    const searchValue = this.form.controls.keyword.value;
    if (searchValue) {
      this.members = [...this.newMembers, ...this.members].filter(member => member.displayName.toLowerCase().includes(searchValue.toLowerCase()) || member.email.toLowerCase().includes(searchValue.toLowerCase()));
    } else {
      this.members = [...this.newMembers, ...this.members];
    }
    this.members.forEach((member: UserViewModel) => {
      member.checked = this.selectedData && (this.selectedData.indexOf(member.id) > -1 || this.selectedData.findIndex(f => isString(f) && f.indexOf(member.email) > -1) > -1);
    });
    if(this.members.length){
      const selectedMembers = this.members.filter(member => member.checked);
      if(selectedMembers.length){
        this.diableonsearching = false;
      }
    }
    this.playersFormArray.controls[0].get('firstName').patchValue(searchValue);
    
  }

  private updateSelectedNoOneSpecific() {
    this.isNoOneSpecific = false;
    if (this.selectedData && this.selectedData.indexOf(-1) > -1) {
      this.isNoOneSpecific = this.selectedData && this.selectedData.indexOf(-1) > -1;
    }
  }

  private resetAddPlayerForm() {
    this.addPlayerForm.reset();
    this.addPlayerForm.setControl('players', this.formBuilder.array([this.createAddPlayerItemForm()]));
    this.isFormSubmited = false;
  }

  private resetSearchForm() {
    this.form.reset({}, { emitEvent: false });
    this.loadUserInputData.search = '';
    this.loadMembers(this.selectedData);
  }

  private addPlayerSuccess() {
    this.updateSelectedValues();
    this.resetAddPlayerForm();
    this.resetSearchForm();
    this.viewmodel.isPlayerCreating = false;
    this.updateData();
  }

  private emailValidator(control: UntypedFormControl) {
    let value = control.value;
    if (!this.viewmodel.inbound && (!value || value.length === 0)) {
      return 'String empty';
    }
    return null;
  }

  private validateUsers() {
    const emails = this.playersFormArray.value.map(player => player.email);
    this.store$.dispatch(new userActions.ValidateUser(emails));
  }

  private validateUsersSuccess(vuRes: ValidateUserOutApiModel[]) {
    this.playersFormArray.controls.forEach((fgItem: UntypedFormGroup) => {
      const checkAllowAddToStory = vuRes.find(f => f.email === fgItem.controls.email.value && f.story_invite_enabled === true);
      if (!checkAllowAddToStory) {
        this.isHideErrorEmailInput = false;
        fgItem.controls.email.setErrors(
          {
            isCustom: true,
            message: 'Unavailable to add. Please try another email!'
          }
        );
      }
    });

    this.addPlayer();
  }

  private addPlayer() {
    if (!this.addPlayerForm.valid) {
      this.viewmodel.isPlayerCreating = false;
      return;
    }

    this.isPlayerAdding = true;
    this.playersFormArray.controls.forEach((fgItem: UntypedFormGroup) => {
      let createUserData = new UserViewModel(null);
      createUserData.firstname = fgItem.controls.firstName.value;
      createUserData.lastname = '';
      createUserData.email = fgItem.controls.email.value || '';
      createUserData.displayName = `${createUserData.firstname}`;
      createUserData.id = `${createUserData.firstname} <${createUserData.email}>`;
      createUserData.checked = true;
      this.newMembers.unshift(createUserData);
      this.selectedData = this.selectedData || [];
      const newUserValue = `${createUserData.firstname} <${createUserData.email}>`;
      this.selectedData.push(newUserValue);
    });
    this.addPlayerSuccess();
  }

  public onYReachEnd() {
    if (this.loadMore && this.viewmodel.scrollbarVisible && !this.isCopyCode && this.router.url != '/directory') {
      this.loadUserInputData.pagination.page += 1;
      //this.viewmodel.loadingDataOnScroll = true;
      this.loadMembers([]);
    }
  }

  public onPositionChanged() {
    this.viewmodel.scrollbarVisible = true;
    if (this.loadMore && this.viewmodel.scrollbarVisible && !this.isCopyCode && this.router.url == '/directory') {
      this.loadUserInputData.pagination.page += 1;
      this.viewmodel.loadingDataOnScroll = true;
      this.loadMembers([]);
    }
  }

  public selectItem(player: UserViewModel) {
    this.selectedData = this.selectedData && this.selectedData.filter((id: number) => (this.utility.Lodash.isNumber(id) && id > 0) || this.utility.Lodash.isString(id)) || [];
    this.isNoOneSpecific = false;
    this.form.controls.keyword.reset()
    this.diableonsearching = false;

    if (player.checked === true) {
      this.selectedData.push(player.id);
    } else {
      const index = this.selectedData.findIndex(f => f === player.id);
      this.selectedData.splice(index, 1);
    }

    this.updateData();
  }

  public checkDisabled(member: any) {
    return this.selectedData && this.selectedData.length >= 3 && !member.checked;
  }

  public memberTrackByFnc(item) {
    return item.id;
  }

  public addPlayerFormSubmit() {
    const emails = this.playersFormArray.value.map(player => player.email);
    // emails.forEach(email => {
    //   const splitEmail = email.split('@');
    //   const emailDomain = splitEmail[1];
    //   if (!this.company.domains.find(domain => domain.domain === emailDomain)) {
    //     this.playersFormArray.controls.forEach((fgItem: FormGroup) => {
    //       if (fgItem.controls.email.value === email) {
    //         this.isHideErrorEmailInput = false;
    //         fgItem.controls.email.setErrors(
    //           {
    //             isCustom: true,
    //             message: 'Invalid domain!'
    //           }
    //         );
    //       }
    //     });
    //   }
    // });
    if (this.addPlayerForm.invalid) return;
    this.isFormSubmited = true;
    this.viewmodel.isPlayerCreating = true;

    this.validateUsers();
  }

  public updateData() {
    this.loadMembers(this.selectedData);
  }

  public handleComKeyEnter() {
  }

  changeData(data){
    if(typeof data === 'string' ){
      if(data != '-1'){
        this.requestForm.controls.about.setValue(this.questionList.find(item => item.id == +data).question_text)
        this.readOnlyforTextfiled = false;
        this.requestForm.controls.campaign.reset();
        this.requestForm.controls.campaign.setValue(-1);
        this.requestForm.controls.question_id_campaign.setValue(-1)

      }
  
 
    }
  }


  public exceptHandleComKeyEnter(event) {
    event.stopPropagation();
  }
  public exceptHandleComKeyEnterKey(event) {
    event.stopPropagation();
  }

  get playersFormArray() {
    return this.addPlayerForm.get('players') as UntypedFormArray;
  }

  public addPlayerFormItem() {
    this.playersFormArray.push(this.createAddPlayerItemForm());
  }

  public removeAddPlayerFormItem($event, index) {
    $event.stopPropagation();
    this.playersFormArray.removeAt(index);
  }



  cancel() {
    this.onClose.emit(false);
  }
  

  GenearateInternalLink() {
    this.isLoading = true;
    const selectedMembers = this.members.filter(member => member.checked);
    if (selectedMembers.length) {
      let payload = {
        author_emails: selectedMembers.map((member: UserViewModel) => { return member.email }),
        campaign_id: this.initialState.id,
        story_requested: 1,
        interview_style: this.initialState.interview_style
      }
      if(this.requestForm.value.internalmessage){
        payload['message']=this.requestForm.value.internalmessage
      }
      if(+this.requestForm.value.userselcted != +this.loggedInUserId){
        payload['requested_by_id'] = this.requestForm.value.userselcted;
      }
      this.requestService.request(payload).subscribe((data: any) => {
        this.isLoading = false;
        this.isCopyCode = true;
        this.requestedUsers = data
        this.checkForSelectedUsers(data);
        this.requestForm.patchValue({
          requestedUser: data[0].url,
          bodyCode: data[0].url
        })
      })
    }
    else{
      this.toastService.warning("Please select any option to continue")
    }
  }

  GenearateExternalLink() {
    this.isLoading = true;
      let payload = {
        author_emails: this.requestForm.controls.email.value ?  [this.requestForm.controls.email.value] : [],
        campaign_id: this.initialState.id,
        story_requested: 1,
        interview_style: this.initialState.interview_style
      }
      if(this.requestForm.controls.firstname.value){
        payload['firstname']=this.requestForm.controls.firstname.value;
      }
      if(this.requestForm.controls.lastname.value){
        payload['lastname']=this.requestForm.controls.lastname.value;
      }
      if(this.requestForm.controls.note_email.value){
        payload['note_email']=this.requestForm.controls.note_email.value;
      }
      if(this.requestForm.value.message){
        payload['message']=this.requestForm.value.message
      }
      if(+this.requestForm.value.externalUserSelcted != +this.loggedInUserId){
        payload['requested_by_id'] = this.requestForm.value.externalUserSelcted;
      }
      this.requestService.request(payload).subscribe((data: any) => {
        this.isLoading = false;
        this.requestedUsers = data
        this.checkForExternalSelectedUsers(data);
      })
    }
  

  
    private checkForExternalSelectedUsers(data){
      this.requestForm.controls.firstname.disable();
      this.requestForm.controls.lastname.disable();
      this.requestForm.controls.email.disable();
      this.isdisplay = true;
      var tempDatastorage = []
      this.hideSendanCopylinkButton = true;
      this.isSingleInternalRequested = true;
      if(data.length == 1){
      
         this.externalUrlforCopy  = data[0].url;
          if(data && data[0].story && data[0].story?.storyid){
            let emailPaylooad = {}
            let emails =  {
              author_email: data[0].email,
              story_id: data[0].story?.id
             }


             this.emailSendIngPayload.push(emails)
          }
          else{
            let emailPayload  =  {
              author_emails: this.requestForm.value.email,
              // firstname:this.requestForm.value.firstname,
              // lastname:this.requestForm.value.lastname,
              // campaign_id: this.initialState.id,
              // story_requested: 1
             }
            this.emailSendIngPayload.push(emailPayload)
            // this.isSingleInternalRequested = true;
          }
         
      }
  
  
    }

    sendExternalEmail(){
      let emails = {
        author_emails:this.emailSendIngPayload
      }
      this.store$.dispatch(new storyAction.SendEamilInternalExternal(emails));
    }

  private checkForSelectedUsers(data: any) {
    this.isdisplay = true;
    var tempDatastorage = []
    this.hideSendanCopylinkButton = true;
    if(data.length == 1){
       this.InternalStorySingleUrl  = data[0].url;
       let emailPaylooad  =  {
        author_email: data[0].email,
        story_id: data[0].story?.id,
        campaign_id:this.initialState.id,
        interview_style: this.initialState.interview_style
       }
       this.emailSendIngPayload.push(emailPaylooad)
       this.members.forEach((item, i) => {
        data.forEach((items, j) => {
          if (items.email == item.email) {
            item["url"] = items.url
            tempDatastorage.push(item)
          }
        })
      })
  this.members = [];
  this.members = tempDatastorage
    }
    else if (data.length > 1 ){
        data.forEach((author)=>{
           let multipleEmailPayload = {
            author_email: author.email,
            story_id: author.story?.id,
            campaign_id:this.initialState.id,
            interview_style: this.initialState.interview_style
           } 
           this.emailSendIngPayload.push(multipleEmailPayload)
        })
        this.members.forEach((item, i) => {
          data.forEach((items, j) => {
            if (items.email == item.email) {
              item["url"] = items.url
              tempDatastorage.push(item)
            }
          })
        })
    this.members = [];
    this.members = tempDatastorage
    }


    
  }

  chnageExternaladdPersonalize(data){
  //  this.requestForm.controls.firstname.reset();
  //  this.requestForm.controls.lastname.reset();
  //  this.requestForm.controls.email.reset();
   this.externalUrlforCopy = '';
   this.emailSendIngPayload = [];
   this.isSingleInternalRequested = false;
   this.hideSendanCopylinkButton = false;
   if(this.requestForm.value.addPersonalize){
    this.requestForm.get('message').updateValueAndValidity();
    this.requestForm.get('message').setValidators([Validators.required]);
   }
   else{
    this.requestForm.get('message').clearValidators();
    this.requestForm.get('message').updateValueAndValidity();
    this.userstatus(null)
   }
  }

  sendIntenalEmail(){
    let emails = {
      author_emails: this.emailSendIngPayload
    }
    this.store$.dispatch(new storyAction.SendEamilInternalExternal(emails));
    // this.requestForm.();
    this.requestForm.controls.internalmessage.reset();
    this.members = [];
    this.selectedData = [];
    this.emailSendIngPayload = [];
    this.isCopyCode = false;
    this.hideSendanCopylinkButton = false;
    this.requestForm.controls.userStatus.setValue('user');
    this.loadUserInputData.pagination.page = 1;
    this.loadUserInputData.pagination.sort = 'desc'
    this.toastService.success('Sent')
    this.loadMembers([]);
  }

  Changecampaigns(data){
    if(typeof data === 'string' && data != '-1'){
       //console.log(data);
       let storyPrompt = this.questionModels.find(item => item.id == +data)
       if(storyPrompt){
        // this.requestForm.controls.about.setValue(storyPrompt.storyPrompt);
        this.requestForm.controls.campaign.setValue(storyPrompt.campaign_id)
       }
       else{
        this.requestForm.controls.about.setValue('');
       }

       this.readOnlyforTextfiled = true;
       this.requestForm.controls.question.reset();
       this.requestForm.controls.question.setValue(-1);
    }
  }

  sendEmail(){

  }

  userstatus(data){

    if(this.requestForm.value.userStatus == 'Guestuser' && !this.isSingleInternalRequested){
       this.requestForm.get('email').updateValueAndValidity();
       this.requestForm.get('email').setValidators([Validators.email]);
       this.requestForm.controls.externalUserSelcted.setValue(this.loggedInUserId);
       this.isSingleInternalRequested = false;
       this.hideSendanCopylinkButton = false;
       this.emailSendIngPayload = [];
       this.isShowPromptContainer = false;
    }
    else if(this.requestForm.value.userStatus == 'Guestuser'  &&  this.isSingleInternalRequested){
      this.requestForm.controls.firstname.disable();
      this.requestForm.controls.lastname.disable();
      this.requestForm.controls.email.disable();
      this.requestForm.controls.note_email.disable();
      this.isSingleInternalRequested = true;
      this.hideSendanCopylinkButton = true;
      this.emailSendIngPayload = [];
      this.isShowPromptContainer = false;
      
   }
    else{
      if(!this.isCopyCode){
        this.members = [];
        this.selectedData = [];
        this.isCopyCode = false;
        this.loadUserInputData.pagination.page = 1;
        this.loadUserInputData.pagination.sort = 'desc'
        this.loadMembers([]);
        this.requestForm.controls.userStatus.setValue('user');
        this.hideSendanCopylinkButton = false;
        this.emailSendIngPayload = [];
        this.isShowPromptContainer = false;
        this.requestForm.controls.internalmessage.reset();
      }
      else{
        this.requestForm.controls.internalmessage.reset();
        this.requestForm.controls.userStatus.setValue('user');
        this.hideSendanCopylinkButton = true;
        this.requestForm.controls.internalmessage.reset();;

      }

    }
  }

  GenearateLink(){

  }

  sendExternalMainEmail(){
    let emails = {
      author_emails:this.emailSendIngPayload
    }
    this.store$.dispatch(new storyAction.SendEamilInternalExternal(emails));
   this.requestForm.controls.firstname.reset();
   this.requestForm.controls.lastname.reset();
   this.requestForm.controls.email.reset();
   this.externalUrlforCopy = '';
   this.emailSendIngPayload = [];
   this.isSingleInternalRequested = false;
   this.hideSendanCopylinkButton = false;
  }

  copyMessage(player, data: any) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = player.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    $(data).attr('title', 'Copied');

    player.linkCopied = true;
    setTimeout(() => {
      $(data).attr('title', 'Click to copy');
      player.linkCopied = false;
    }, 1000)
  }

  copyExternalUrl() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.externalUrlforCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.buttonText = 'Copied'
    this.toastService.success("Copied");
    setTimeout(() => {
      this.buttonText = 'Copy Link'
    }, 1000)
  }
  

  public close() {
    this.onClose.emit();
  }

  shareadthis(){
    this.isDispalyadthis = true;
    setTimeout(()=>{
      if (typeof (<any>window).shareholic !== 'undefined') { (<any>window).shareholic.layers.refresh(); }
      this.shareholic.nativeElement.setAttribute("data-link", this.externalUrlforCopy);
    },1000)
  }
  closeshare(){
    this.isDispalyadthis = false;
  }

}
