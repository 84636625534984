<div class="policy_container">
    <div class="row img_container" *ngIf="company_response">
        <img [src]="company_response?.logo_media?.fullsize" alt="">
    </div>
    <h1 class="consent_text" style="margin-left: 1.8rem;">{{consent_name}}</h1>
    <div class="modal-body" [formGroup]="consentForm">
        <div class="row mb-4" *ngIf="storyId">
            <div class="col-12 mb-1"><span class="headlines">Title:</span> {{story_details?.title}}</div>
            <div class="col-12 mb-1"><span class="headlines">Author:</span> {{story_details?.author?.display_name}}</div>
            <div class="col-12 mb-1"><span class="headlines">Date:</span> {{story_details?.published_at | date}}</div>
          </div>
        <div class="template_html">
          <div [innerHtml]="template"></div>
        </div>
      <div class="option_body">
      <h6 *ngIf="show_name">
         Use of Name <span class="madatory">*</span>
      </h6>
      <div class="row mb-4" *ngIf="show_name">
        <div class="col-12"  *ngIf="responseData?.is_real_name">
          <div class="custom-control custom-radio">
              <label class="mb-0">
                  <input type="radio" formControlName="from_name" value="is_real_name"
                      class="custom-control-input">
                  <span class="custom-control-label">Full Name can be used</span>
              </label>
          </div>
      </div>
      <div class="col-12"  *ngIf="responseData?.is_first_name">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" formControlName="from_name" value="is_first_name"
                    class="custom-control-input">
                <span class="custom-control-label">First Name only can be used</span>
            </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_initials_name">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" formControlName="from_name" value="is_initials_name"
                    class="custom-control-input">
                <span class="custom-control-label">Initials only can be used</span>
            </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_fake_name">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" formControlName="from_name" value="is_fake_name"
                    class="custom-control-input">
                <span class="custom-control-label">Fake First Name only</span>
            </label>
        </div>
      </div>
      </div>
      <h6 *ngIf="show_image">
          Use of Image or Likeness <span class="madatory">*</span>
      </h6>
      <div class="row mb-4" *ngIf="show_image">
        <!--<div class="col-12" *ngIf="responseData?.is_full_image">
          <div class="custom-control custom-radio">
              <label class="mb-0">
                  <input type="radio" formControlName="from_image" value="is_full_image"
                      class="custom-control-input">
                  <span class="custom-control-label">I allow my image or likeness in all media</span>
              </label>
          </div>
        </div>-->
        <div class="col-12" *ngIf="responseData?.is_image_in_any_media">
            <div class="custom-control custom-radio">
                <label class="mb-0">
                    <input type="radio" formControlName="from_image" value="is_image_in_any_media"
                        class="custom-control-input">
                    <span class="custom-control-label">I allow my image or likeness in any media</span>
                </label>
            </div>
          </div>
          <!--<div class="col-12" *ngIf="responseData?.is_video_use">
            <div class="custom-control custom-radio">
                <label class="mb-0">
                    <input type="radio" formControlName="from_image" value="is_video_use"
                        class="custom-control-input">
                    <span class="custom-control-label">I allow my image or likeness in video only</span>
                </label>
            </div>
          </div>
          <div class="col-12"  *ngIf="responseData?.is_image_social_media">
            <div class="custom-control custom-radio">
                <label class="mb-0">
                    <input type="radio" formControlName="from_image" value="is_image_social_media"
                        class="custom-control-input">
                    <span class="custom-control-label">I allow my image or likeness in social media only</span>
                </label>
            </div>
          </div>-->
       
          <div class="col-12"  *ngIf="responseData?.is_blurred_image">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" formControlName="from_image" value="is_blurred_image"
                    class="custom-control-input">
                <span class="custom-control-label">My image or likeness must be blurred or hidden</span>
            </label>
        </div>
      </div>
      
      <div class="col-12" *ngIf="responseData?.not_use_image">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" formControlName="from_image" value="not_use_image"
                    class="custom-control-input">
                <span class="custom-control-label">Do not use my image or likeness</span>
            </label>
        </div>
      </div>
      </div>
      <h6 *ngIf="show_voice"> 
          Use of Voice <span class="madatory">*</span>
      </h6>
      <div class="row mb-4" *ngIf="show_voice">
        <div class="col-12" *ngIf="responseData?.is_real_voice" >
          <div class="custom-control custom-radio">
              <label class="mb-0">
                  <input type="radio" formControlName="from_voice" value="is_real_voice"
                      class="custom-control-input">
                  <span class="custom-control-label">My voice can be used</span>
              </label>
          </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_masked_voice">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" formControlName="from_voice" value="is_masked_voice"
                    class="custom-control-input">
                <span class="custom-control-label">My voice must be masked or adjusted</span>
            </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_voice_transcription">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" formControlName="from_voice" value="is_voice_transcription"
                    class="custom-control-input">
                <span class="custom-control-label">Do not use my voice, but transcription is allowed.</span>
            </label>
        </div>
      </div>
      </div>
      
      <h6 *ngIf="show_other">
        Other Uses
      </h6>
      <div class="row mb-4" *ngIf="show_other">
      <div class="col-12" *ngIf="responseData?.is_name_advertising">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" formControlName="from_other" value="is_name_advertising"
                    class="custom-control-input">
                <span class="custom-control-label">I allow my name to be used in advertising</span>
            </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_image_media_advertising">
      <div class="custom-control custom-radio">
          <label class="mb-0">
              <input type="radio" formControlName="from_other" value="is_image_media_advertising"
                  class="custom-control-input">
              <span class="custom-control-label">I allow my image or likeness to be used in advertising</span>
          </label>
      </div>
      </div>
      </div>
      <div class="sign_container" *ngIf="signatureNeeded === false">
          <img src="{{ signatureImg }}" alt="" srcset="" />
        </div>
      <div class="signature_content">
          <!-- app.component.html -->
          <h6>Please Add your signature here <span class="madatory">*</span></h6>
          <canvas
            #canvas
            (touchstart)="startDrawing($event)"
            (touchmove)="moved($event)"
            width="280"
            height="80"
            style="border: 1px solid #efefef;"
          ></canvas>
          <p class="error-message" *ngIf="signatureNeeded">Signature is required</p>
          
          <div class="buttons flex justify-between mt-2" *ngIf="storyId">
            <button class="btn" (click)="clearPad()">Clear</button>
            <button  class="btn" (click)="savePad()">Submit the signature</button>
          </div>
          
          </div>
        </div>
      <div class="modal-footer" *ngIf="storyId">
        <div>
          <button (click)="close()" type="button" data-dismiss="modal"
          class="btn btn-outline btn-gray-light min-width">Cancel</button>
        <button (click)="submitForm()" [disabled]="consentForm.invalid || isDisabled"  type="button" class="btn btn-pink min-width">
          <i
          *ngIf="isUpdating" class="fas fa-spinner fa-pulse"></i>
         Save
        </button>
        </div>
      
      </div>
      </div>
  </div>
  