import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ComponentBase } from '@framework/base/component/component.base';
import { LandingPageViewModel, CompanyViewModel, SearchBoxStateViewModel } from '@modules/company/models/store-view-models';
import { CoreState } from '@app/store/core/states';
import { landingPageSelectors, companySelectors, searchBoxSelectors } from '@modules/company/store/selectors';
import { GsMasonryComponent } from '@modules/shared/components/layout/gs-masonry';
import { StoryViewModel } from '@modules/story/models/view-models';
import { LoadStatusModel } from '@modules/shared/models';
import { ImageCloudinaryPipe } from '@modules/shared/pipes';
import { storySelector } from '@modules/story/store/selectors';
import { ActivatedRoute } from '@angular/router';
import { companyActions, campaignActions, searchBoxActions, landingPageActions } from '@modules/company/store/actions';
import { storyActions, storyStepActions } from '@modules/story/store/actions';
import { Actions, ofType } from '@ngrx/effects';
import { SearchStoryInputApiModel } from '@modules/story/models/api-models/input';
import * as adminStoryActions from '@modules/company-admin/store/actions';
@Component({
  selector: 'co-pb-lp-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent extends ComponentBase {
  @ViewChild(GsMasonryComponent)
  gsMasonryComponent: GsMasonryComponent;
  // Private variables
  public showIndicator: boolean = false;
  public disabled: boolean = false;
  private selectStories$: Observable<StoryViewModel[]>;
  private selectLoadStoryStatus$: Observable<LoadStatusModel>;
  private selectLandingPageDetail$: Observable<LandingPageViewModel>;

  private selectLandingPageDetailSubscription: Subscription;
  private selectSearchBoxStatusSubscription: Subscription;
  private selectStoriesSubscription: Subscription;
  private selectLoadStoryStatusSubscription: Subscription;
  // Public variables
  public stories: StoryViewModel[] = [];
  public getPayload:any;
  public userLables:any;
  public GuestLables:any;
  private getOrderStoriesSuccessSubscription: Subscription;
  public isViewList: boolean = false;
  public loadStoryStatus: LoadStatusModel;
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  public landingPage: LandingPageViewModel;
  private featuredSlug: string = '';
  private selectCompany$: Observable<CompanyViewModel>;
  private selectCompanySubscription: Subscription;
  private loadColorSuccessSubscription: Subscription;
  public selectedCompany: CompanyViewModel;
  private loadStepSuccessSubscription: Subscription;
  private selectSearchBoxStatus$: Observable<SearchBoxStateViewModel>;
  public searchBoxStatus: SearchBoxStateViewModel;
  public queryStoriesData: SearchStoryInputApiModel = new SearchStoryInputApiModel();
  public stepsData: any;
  public user_label_text:any;
  public guest_label_text:any;

  constructor(private actions$: Actions, private activatedRoute: ActivatedRoute, private store$: Store<CoreState>, public imageCloudinaryPipe: ImageCloudinaryPipe) {
    super();
    this.selectStories$ = this.store$.select(storySelector.selectAll);
    this.selectLoadStoryStatus$ = this.store$.select(storySelector.selectLoadStatus);
    this.selectLandingPageDetail$ = this.store$.select(landingPageSelectors.selectLandingPage);
    this.selectCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectSearchBoxStatus$ = this.store$.select(searchBoxSelectors.selectSearchBoxStatus);
  }

  // Life cycle hook
  protected onInit(): void {
    this.captureParams();
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {
    this.selectCompanySubscription = this.selectCompany$.subscribe((company) => {
      if (company) {
        this.selectedCompany = company;
        this.store$.dispatch(new storyStepActions.Load(this.selectedCompany.id));
        this.loadDetail();
      }
    });

    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<adminStoryActions.storyActions.GetColorSuccess>(adminStoryActions.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.userLables = action.response.find(item => item.button == 1).color;
        this.GuestLables = action.response.find(item => item.button == 2).color;
        this.user_label_text = action.response.find(item => item.button == 18).color;
        this.guest_label_text = action.response.find(item => item.button == 19).color;
        // this.boxshadowcolr = '0 -0.1875rem 0'+action.response.find(item => item.button == 6).color+'inset;'
       }
      

    })

    this.loadStepSuccessSubscription = this.actions$.pipe(
      ofType<storyStepActions.LoadSuccess>(storyStepActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((result) => {
      if (result.data) {
        this.stepsData = result.data;
      }
    });

    this.getOrderStoriesSuccessSubscription = this.actions$.pipe(
      ofType<landingPageActions.GetOrderStoriesSuccess>(landingPageActions.ActionTypes.GET_ORDER_STORIES_SUCCESS)
    ).subscribe((action:any) => {
     
      if(action && action.response && action.response.length){
        this.getPayload = action.response;
        // //console.log(">>",action);
        // //console.log(">>",this.stories)
      }
    })

    this.selectStoriesSubscription = this.selectStories$.subscribe((stories) => {
      if(this.landingPage?.queryObjects?.facetdeletedIds?.length){
        stories= stories.filter(story => !this.landingPage.queryObjects.facetdeletedIds.includes(story.id.toString()))
       }
      if(stories && stories.length){
        if(this.getPayload &&  this.getPayload.length){
          stories.forEach((items,i)=>{
             if(this.getPayload.find(item => item.story_id == items.id)){
              items['order'] = this.getPayload.find(item => item.story_id == items.id).order
             }
             else{
              items['order'] = i + stories.length + 200
             }
          })
          stories = stories.sort((a,b)=> a.order - b.order)
        }
       }
      this.stories = stories || [];
      this.showIndicator = false;
      setTimeout(() => {
        this.reloadMansonry();
      }, 1000);
    });

    this.selectLoadStoryStatusSubscription = this.selectLoadStoryStatus$.subscribe((loadStoryStatus) => {
      this.loadStoryStatus = loadStoryStatus;
      if (loadStoryStatus) {
        if (loadStoryStatus.isLoaded && loadStoryStatus.isCanLoadMore) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      }
    });

    this.selectLandingPageDetailSubscription = this.selectLandingPageDetail$.subscribe((landingPage) => {
      if (landingPage) {
        this.landingPage = landingPage;
        this.store$.dispatch(new landingPageActions.GetOrderStories(this.landingPage.id))
        this.generateQueryandSettoLocalStorage();
      }
    });

    this.selectSearchBoxStatusSubscription = this.selectSearchBoxStatus$.subscribe((state: SearchBoxStateViewModel) => {
      this.searchBoxStatus = state;
    });
  }

  private unsubscribe() {
    this.selectCompanySubscription.unsubscribe();
    this.selectStoriesSubscription.unsubscribe();
    this.selectLoadStoryStatusSubscription.unsubscribe();
    this.selectLandingPageDetailSubscription.unsubscribe();
    this.loadStepSuccessSubscription.unsubscribe();
    this.getOrderStoriesSuccessSubscription.unsubscribe()
    this.loadColorSuccessSubscription.unsubscribe();
    this.store$.dispatch(new storyActions.ResetState());
  }

  private captureParams() {
    this.featuredSlug = this.activatedRoute.snapshot.params['pageSlug'];
    const companySlug = this.activatedRoute.snapshot.params['slug'];
    this.store$.dispatch(new companyActions.LoadPublicInfo(companySlug));
  }

  private loadDetail() {
    this.store$.dispatch(new campaignActions.LoadDetail(this.selectedCompany.id, this.featuredSlug));
  }

  private reloadMansonry() {
    this.gsMasonryComponent.reloadLayout();
  }
  // Public functions
  public trackByFunc(story: StoryViewModel) {
    return story.id;
  }
  public closeSearchBox() {
    this.store$.dispatch(new searchBoxActions.Hide());
  }

  public showSearchBox() {
    this.store$.dispatch(new searchBoxActions.Show());
  }

  public changeSortType() {
    if (this.queryStoriesData.pagination.sort === 'desc') {
      this.sort('asc');
    } else {
      this.sort('desc');
    }
  }

  // generateQueryandSettoLocalStorage(){
  //   localStorage.removeItem('publicfilterDetails');
  //   var filter = '';
  //   var otherfilters = ''
  //   if(this.landingPage && this.landingPage.queryObjects && this.landingPage.queryObjects.queryStories){
  //     var keys = Object.keys(this.landingPage.queryObjects.queryStories);
  //      if(keys.includes('tags') && this.landingPage?.queryObjects?.facetedTagSelected?.length){
  //       filter+= `tags:${this.landingPage.queryObjects.queryStories.tags.join(',')}|`
  //      }
  //      if(keys.includes('campaigns') && this.landingPage?.queryObjects?.facetedCampaignSelected?.length){
  //       filter+= `campaigns:${this.landingPage.queryObjects.queryStories.campaigns.join(',')}|`
  //      }
  //      if(keys.includes('authors') && this.landingPage?.queryObjects?.facetedPeopleSelected?.length){
  //       filter+= `authors:${this.landingPage.queryObjects.queryStories.authors.join(',')}|`
  //      }
  //      if(keys.includes('keywords')){
  //       otherfilters+= `keyword=${this.landingPage.queryObjects.queryStories.keywords.join(',')}&`
  //      }
  //      if(!this.landingPage?.queryObjects?.queryStories.includeTestimonial){
  //       otherfilters+= `includeTestimonial=no&`
  //      }
  //      if(this.landingPage?.queryObjects?.queryStories.public){
  //       otherfilters+= `public=1&`
  //      }
  //      if(this.landingPage?.queryObjects?.queryStories.internal){
  //       otherfilters+= `internal=1&`
  //      }

  //      var finalFilter = `filter = ${filter}&${otherfilters}page=1&limit=1000&sort=-published_at&spotlight_only=True`
  //      localStorage.setItem('publicfilterDetails',finalFilter);

  //   }
  // }

  generateQueryandSettoLocalStorage(){
    localStorage.removeItem('publicfilterDetails');
    var filter = '';
    var tags = '';
    var campaigns = '';
    var authors = ''
    var otherfilters = ''
    if(this.landingPage && this.landingPage.queryObjects && this.landingPage.queryObjects.queryStories){
      var keys = Object.keys(this.landingPage.queryObjects.queryStories);
       if(keys.includes('tags') && this.landingPage?.queryObjects?.facetedTagSelected?.length){
        tags+= `tags:${this.landingPage.queryObjects.queryStories.tags.join(',')}`
       }
       if(keys.includes('campaigns') && this.landingPage?.queryObjects?.facetedCampaignSelected?.length){
         if(tags){
          campaigns+= `|campaigns:${this.landingPage.queryObjects.queryStories.campaigns.join(',')}`
         }
         else{
          campaigns+= `campaigns:${this.landingPage.queryObjects.queryStories.campaigns.join(',')}`
         }
       }
       if(keys.includes('authors') && this.landingPage?.queryObjects?.facetedPeopleSelected?.length){
         if(campaigns){
          authors+= `|authors:${this.landingPage.queryObjects.queryStories.authors.join(',')}`
         }
         else{
          authors+= `authors:${this.landingPage.queryObjects.queryStories.authors.join(',')}`
         }
       }
       if(keys.includes('keywords')){
        otherfilters = `keyword=${this.landingPage.queryObjects.queryStories.keywords.join(',')}&`
       }
       if(!this.landingPage?.queryObjects?.queryStories?.includeTestimonial){
        otherfilters+= `includeTestimonial=no&`
       }
       if(this.landingPage?.queryObjects?.queryStories.public){
        otherfilters+= `public=1&`
       }
       if(this.landingPage?.queryObjects?.queryStories.internal){
        otherfilters+= `internal=1&`
       }

      if(tags){
        filter+=tags;
      }
      if(campaigns){
        filter+=campaigns;
      }
      if(authors){
        filter+=authors;
      }

   var finalFilter = 'page=1&limit=1000&sort=-published_at&spotlight_only=True&'
    if(filter){
     finalFilter+=`filter=${filter}&`
    }
    if(otherfilters){
      finalFilter+= `${otherfilters}`
    }
     localStorage.setItem('publicfilterDetails',finalFilter);

    }
  }

  private sort(sortOrder: string) {
    this.queryStoriesData.pagination.sort = sortOrder;
    this.queryStoriesData.pagination.page = 1;
    this.store$.dispatch(new searchBoxActions.Search(
      {
        pagination: this.queryStoriesData.pagination
      }
    ));
  }

  public loadMoreStories() {
    if (this.loadStoryStatus.isLoaded && this.loadStoryStatus.isCanLoadMore) {
      this.showIndicator = true;
      this.queryStoriesData.pagination.page += 1;
      this.queryStoriesData.pagination.sort ='desc'
      this.store$.dispatch(new searchBoxActions.Search(
        {
          pagination: this.queryStoriesData.pagination
        }
      ));
    }
  }

  public changeViewType() {
    this.isViewList = !this.isViewList;
    this.reloadMansonry();
  }
}
