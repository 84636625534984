import { Component, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ComponentBase } from '@framework/base/component';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { Actions, ofType } from '@ngrx/effects';
import { commentActions } from '@modules/shared/store/actions';
import { LoadCommentInputApiModel, CommentInputApiModel } from '@modules/shared/models/api-models/input';
import { PaginationModel } from '@framework/models/pagination-models';
import { Observable, Subscription } from 'rxjs';
import { CommentViewModel, LoadStatusModel } from '@modules/shared/models';
import { commentSelectors } from '@modules/shared/store/selectors';
import { skip } from 'rxjs/operators';
import { ApprovalFlowActions } from '@modules/company-admin/store/actions';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-approver-comments',
  templateUrl: './approver-comment.component.html',
  styleUrls: ['./approver-comment.component.css']
})
export class ApproverCommentComponent extends ComponentBase {
  @Input() resourceType: string;
  @Input() resourceId: number;
  @Input() inbound:boolean = false;
  @Input() isMasterAdmin:boolean = false;
  @Output() noComents:EventEmitter<boolean> = new EventEmitter<boolean>();

  public response_id:any;

  // Private variables
  private selectComments$: Observable<CommentViewModel[]>;
  private selectLoadCommentStatus$: Observable<LoadStatusModel>;

  private selectCommentsSubscription: Subscription;
  private selectLoadCommentStatusSubscription: Subscription;
  private queryCommentsData: LoadCommentInputApiModel = new LoadCommentInputApiModel(new PaginationModel(5));
  // Public variables
  public comments: any[] = [];
  public loadCommentStatus: LoadStatusModel;


  constructor(private store$: Store<CoreState>,
    private activatedROute: ActivatedRoute,
    private actions$: Actions) {
    super();
  }

  // Life cycle hook
  protected onInit(): void {
    this.store$.dispatch(new ApprovalFlowActions.GetComment(this.resourceId, this.activatedROute.snapshot.paramMap.get('responseId')))
    this.response_id = this.activatedROute.snapshot.paramMap.get('responseId');
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unscubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
   this.selectCommentsSubscription = this.actions$.pipe(
    ofType<ApprovalFlowActions.GetCommentSuccess>(ApprovalFlowActions.ActionTypes.GET_COMMENT_SUCCESS)
  ).subscribe((action) => {
    //console.log(">>",action);
    if(action.response && action.response && action.response?.results && action.response?.results?.length  ){
      this.comments = action.response?.results.map((comment)=>{
        return {
          authorId:comment.author_id,
          avatar:comment?.avatar ? comment?.avatar : '',
          displayName:comment?.author_name,
          id:comment?.id,
          content:comment?.content
        }
      })
    }
    
  })
  }

  private unscubscribe() {
    this.selectCommentsSubscription.unsubscribe();
  }

  private loadComments() {
    this.queryCommentsData.pagination.sort = 'desc';
    // this.store$.dispatch(new ApprovalFlowActions.GetComment(this.resourceId, this.resourceType));
  }
  // Public functions
  public loadMore() {
    this.queryCommentsData.pagination.page += 1;
    this.loadComments();
  }

  public trackByFunc(comment: CommentViewModel) {
    return comment.id;
  }
}
