import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { Page } from '@framework/base';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { UserProfileViewModel } from '@modules/user/store/models';
import { ApprovalFlowActions } from '@modules/company-admin/store/actions';
import { Location } from '@angular/common';
import { campaignActions } from '@modules/company/store/actions';
import { userPermissionActions } from '@modules/user/store/actions';
import { Constants } from '@modules/shared';
import { GridViewFilterOptionsModel } from '@modules/shared/components/commons/grid-view-filter/models';
import { GetCampaignInputApiModel } from '@modules/company/models/api-models/input';
import { PaginationModel } from '@framework/models/pagination-models';
import { DatePipe } from '@angular/common';
import { ToastService } from '@framework/services';

@Component({
  selector: 'app-approval-page',
  templateUrl: './approval-page.html',
  styleUrls: ['./approval-page.scss'],
})
export class ApprovalPage extends Page {

  private currentUserProfile: UserProfileViewModel;
  private LoadresponseSubscriptuon: Subscription;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
  private selectCurrentUserProfileSubscription: Subscription;
  private permissionSubscription$: Subscription;
  private selectCampaignSubscription: Subscription;
  public enable_all_approvals:boolean = false;
  public isArchive:boolean = false;
  public loader:boolean = false;
  public masterItems = [];
  private loadCampaignInputModel: GetCampaignInputApiModel = new GetCampaignInputApiModel(new PaginationModel(100));
  items = [
    // Add more items as needed
  ];
  tabName:any = 'AllApprovals'
  public gridViewFilterOptions: GridViewFilterOptionsModel = {
    filters: [
      {
        name: 'campaign',
        label: 'Campaign',
        type: Constants.columnType.select,
        displayField: 'label',
        valueField: 'value',
        defaultValue: null,
        selections: [
          {
            value: null,
            label: 'All',
          }
        ]
      },

      {
        name: 'date',
        label: 'Published',
        type: Constants.columnType.daterange,
        placeholder: 'All Time',
        valueField: 'story_published_date'
      },
      {
        name: 'searchKey',
        label: 'Search',
        type: Constants.columnType.select,
        displayField: 'label',
        valueField: 'value',
        defaultValue: 'task_owner',
        selections: [
          {
            value: 'task_owner',
            label: 'Task Owner',
          },
        ]
      },
    ]
  };
  public gridViewFilterOptionsAllApprovals: GridViewFilterOptionsModel = {
    filters: [
      {
        name: 'campaign',
        label: 'Campaign',
        type: Constants.columnType.select,
        displayField: 'label',
        valueField: 'value',
        defaultValue: null,
        selections: [
          {
            value: null,
            label: 'All',
          }
        ]
      },

      {
        name: 'state',
        label: 'Status',
        type: Constants.columnType.select,
        displayField: 'label',
        valueField: 'value',
        defaultValue: 'pending',
        selections: [
          {
            value: 'pending',
            label: 'In Review',
          },
          {
            value: 'approved',
            label: 'Approved',
          },
          {
            value: null,
            label: 'All',
          },
        ]
      },
      {
        name: 'currentstate',
        label: 'Last Update',
        type: Constants.columnType.select,
        displayField: 'label',
        valueField: 'value',
        defaultValue: null,
        selections: [
          {
            value: null,
            label: 'All',
          },
          {
            value: 'pending',
            label: 'Not Started',
          },
          {
            value: 'approved',
            label: 'Passed By',
          },
          {
            value: 'rejected',
            label: 'Rejected By',
          },
          {
            value: 'published',
            label: 'Published By',
          },
        ]
      },
      {
        name: 'is_archived',
        label: 'Archive',
        type: Constants.columnType.select,
        displayField: 'label',
        valueField: 'value',
        defaultValue: null,
        selections: [
          {
            value: null,
            label: 'No',
          },
          {
            value: 'yes',
            label: 'Yes',
          },

        ]
      },
      {
        name: 'date',
        label: 'Published',
        type: Constants.columnType.daterange,
        placeholder: 'All Time',
        valueField: 'story_published_date'
      },
    ]
  };
  constructor(
    private store$: Store<CoreState>,
    private actions$: Actions,
    private location: Location,
    private toastService: ToastService
  ) {

    super();
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);

  }

  // Life cycle hook
  protected onInit(): void {
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        this.loadCampaignInputModel.isLoadExpired = true;
        // this.switchtab(this.tabName)p
        this.store$.dispatch(new campaignActions.Load(userProfile.company.id, this.loadCampaignInputModel))
        this.store$.dispatch(new userPermissionActions.LoadPermissions())
      }
    })
    this.getUserDetail();
  }
  protected onDestroy(): void {

  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  protected ionViewDidLoad(): void {

  }
  protected ionViewWillEnter(): void {

  }
  protected ionViewDidEnter(): void {

  }
  protected ionViewWillLeave(): void {

  }
  protected ionViewDidLeave(): void {

  }
  protected ionViewWillUnload(): void {

  }

  getUserDetail(){
    this.LoadresponseSubscriptuon = this.actions$.pipe(
      ofType<ApprovalFlowActions.GetListApprovalSuccess>(ApprovalFlowActions.ActionTypes.GET_APPROVAL_LIST_USER_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
         this.items = action.response.filter(item => item.is_approved == null);
         this.masterItems = action.response;
         this.loader = false;
       }
       else{
        this.loader = false;
        this.items = [];
        this.masterItems = [];
       }
      

    })
    this.permissionSubscription$ = this.actions$.pipe(
      ofType<userPermissionActions.LoadPermissionsSuccess>(userPermissionActions.ActionTypes.LOAD_PERMISSION_SUCCESS)
    ).subscribe(action => {
      this.enable_all_approvals = action.response.find(permission => permission.name === 'enable_footnote');
      if(!this.enable_all_approvals){
        this.tabName = 'MyApprovals'
        this.switchtab(this.tabName)
      }
      else{
        this.switchtab(this.tabName)
      }

    });


    this.selectCampaignSubscription = this.actions$.pipe(
      ofType<campaignActions.LoadSuccess>(campaignActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((campaigns: any) => {

      if (campaigns && campaigns.response && campaigns.response.result.length) {
        var storeCampaigs;
        campaigns.response.result.forEach((item) => {
          let Campaign = {
            value: item.id,
            label: item.title
          }
          this.gridViewFilterOptions.filters[0].selections.push(Campaign);
          this.gridViewFilterOptionsAllApprovals.filters[0].selections.push(Campaign);

        })

      }
    })
  }

  // Private functions


  updateStatus(item: any, status: string): void {
    item.status = status;
  }
  public checkHasChildActivated(childPaths: string[]) {
    const segment = this.location.path(true);
    return childPaths.findIndex(f => f === segment) > -1;
  }

  approve(item:any,i){
    let payload ={
      action:'approve'
    }
    item.is_approved = true;
    this.store$.dispatch(new ApprovalFlowActions.ApproveRejectStep(this.currentUserProfile?.company?.id,item.response_id,payload));
    if(this.tabName == 'MyApprovals'){
      this.toastService.success("Story Approved Successfully");
      this.switchtab(this.tabName)
    }
    else{
      item.final_approved = 'Approved';
      item.current_status = 'Approved by'+' '+ this.currentUserProfile?.firstName
      this.toastService.success("Story Approved !!! Please check in stories page");
      // this.switchtab(this.tabName)
    }
 
  }

  reject(item: any,index){

    let payload ={
      action:'reject'
    }
    item.is_approved = false;
    this.store$.dispatch(new ApprovalFlowActions.ApproveRejectStep(this.currentUserProfile?.company?.id,item.response_id,payload));
    // if(this.tabName != 'MyApprovals'){
    //   this.items.f(index)
    // }

    if(this.tabName == 'MyApprovals'){
      this.toastService.success("Story rejected  Successfully");
      this.switchtab(this.tabName)
    }
    else{
      this.toastService.success("Story Rejected and move to its previous step");
      this.switchtab(this.tabName)
    }
  }

  getCurrentFinalApproved(data){
    let updatedText = data.replace("Approved by", "Published by");
    return updatedText
  }

  getApprovedBy(data){
    let updatedText = data.replace("Approved by", "Passed by");
    return updatedText
  }

  getApproved(data){
    return data.includes('Approved')
  }

  archive(item: any,index){

    let payload ={
      action:'archive'
    }
    item.is_archived = true;
    this.store$.dispatch(new ApprovalFlowActions.ApproveRejectStep(this.currentUserProfile?.company?.id,item.response_id,payload));
    // if(this.tabName != 'MyApprovals'){
    //   this.items.f(index)
    // }
    this.switchtab(this.tabName)
    this.toastService.success("Story Archived ");
  }

  unarchieve(item: any,index){

    let payload ={
      action:'unarchive'
    }
    item.is_archived = false;
    this.store$.dispatch(new ApprovalFlowActions.ApproveRejectStep(this.currentUserProfile?.company?.id,item.response_id,payload));
    // if(this.tabName != 'MyApprovals'){
    //   this.items.f(index)
    // }
    this.toastService.success("Story UnArchived ");
  }
  public filter(filterData: any) {
    //console.log("filterData",filterData)
    if(filterData?.campaign  != null || filterData?.date != null || filterData?.searchText != ""){
      this.store$.dispatch(new ApprovalFlowActions.GetListApproval(this.currentUserProfile?.company?.id,this.buildQuery(filterData)))
    }

  }

  public allApprovalfilter(filterData: any){
    if(filterData?.campaign  != null || filterData?.date != null || filterData?.searchText != "" || filterData?.state != null  || filterData?.currentstate != null){
      this.store$.dispatch(new ApprovalFlowActions.GetListApproval(this.currentUserProfile?.company?.id,this.buildQuery(filterData)))
    }
  }

  get itemsList():any[]{
    if(this.items  && this.items.length){
      return this.items = this.items.filter(item => item.is_approved == null || item.is_approved == false)
    }
    else{
      return []
    }
  }
 
  switchtab(tab: any){
    this.loader=true
    this.tabName = tab;
    if(this.tabName == 'AllApprovals'){
    let obj=  {
        "searchText": "",
        "campaign": null,
        "state": "pending",
        "currentstate": null,
        "is_archived": null,
        "date": null
    }
      this.store$.dispatch(new ApprovalFlowActions.GetListApproval(this.currentUserProfile?.company?.id,this.buildQuery(obj)))
    }
    else{
      this.store$.dispatch(new ApprovalFlowActions.GetListApproval(this.currentUserProfile?.company?.id,this.buildQuery(null)))
    }

  }

  rejectFullStory(item,index){
    item.final_approved = 'Pending';
    let payload ={
      story_id:item?.story_id,
      action:"reject"
    }
    this.store$.dispatch(new ApprovalFlowActions.RejectApprovedStory(this.currentUserProfile?.company?.id,payload))
  }


  private buildQuery(filterData:any) {
    let query = '';
    if(this.tabName == 'MyApprovals'){
      query += 'only_me=true&'
    }
    
   
    if(this.tabName != 'MyApprovals'){
      if(filterData && String(filterData?.is_archived) != 'null'){
        query += `is_archived=`+ true + `&`; 
        this.isArchive = true;
       }
       else{
         this.isArchive = false;
       }
       if( filterData && String(filterData?.currentstate) != 'null'){
        if(filterData?.currentstate == 'published'){
         filterData.state = 'approved'
        }
        else{
          query += `c_state=`+ filterData?.currentstate + `&`; 
        }

       }
    }

    if(filterData){
      if (filterData && String(filterData.state) != "null") {
        query += `status=` + filterData.state + `&`;
    }

    if (String(filterData.campaign) != "null") {
      query += `campaign=` + filterData.campaign + `&`;
    }

    if (filterData.date && filterData.date[0] != "null") {
      const publishedFrom = new Date(filterData.date[0]);

      // Create an instance of the DatePipe
      const datePipe = new DatePipe('en-US');

      const formattedDate = datePipe.transform(publishedFrom, 'yyyy-MM-dd').toString();
      query += `date_published_before=${formattedDate}&`;
    }

    if (filterData.date && filterData.date[1] != "null") {
        const publishedFrom = new Date(filterData.date[1]);

        // Create an instance of the DatePipe
        const datePipe = new DatePipe('en-US');

        const formattedDate = datePipe.transform(publishedFrom, 'yyyy-MM-dd').toString();
        query += `date_published_after=${formattedDate}&`;
    }


    if (filterData.searchText) {
        query += `search_filter=${filterData.searchText}&`;
    }
    }



    return query;
}

}
