import { SearchCompanyOutputApiModel, InfoCompanyOutputApiModel } from '../../models/api-models/output';

export interface CustomUrlEntity {
    label: string;
    url: string;
}

export interface AuthorEntity {
    authorId: number;
    displayName: string;
    title: string;
    avatar: string;
}

export interface SocialSiteEntity {
    name: string;
    image: string;
    url: string;
}

export interface CompanyButtonEntity {
    label: string;
    url: string;
    foreground: string;
    background: string;
    isSelected: boolean;
}

export interface FrequentTagItemEntity {
    color: string;
    data: string[];
    sort: number;
    title: string;
    is_header_part: boolean
    header_title?:any

}

export interface FrequentTagEntity {
    [key: number]: FrequentTagItemEntity;
}

export interface CompanyEntity {
    id: number;
    name: string;
    logo: string;
    email: string;
    domains: Domain[];
    website: string;
    background: string;
    buttons: CompanyButtonEntity[];
    slogan: string;
    blurb: string;
    author: AuthorEntity;
    socialSites: SocialSiteEntity[];
    countStories: number;
    countAuthors: number;
    countContributors: number;
    countCampaigns: number;
    countFeaturedPages: number;
    slug: string;
    pagePublic: boolean;
    isInboundStoriesEnabled: boolean;
    frequentTags: FrequentTagEntity;
    isCampaignCompany: boolean;
    showLogoHeader: boolean;
    enable_public?: boolean,
    is_logo_updated: boolean;
    enable_tags?:boolean;
    default_story_prompt: any;
    company_sendgrid_details: any;
    internal_stories_approval: any;
    enable_overlay?: boolean;
    show_goodseeker_branding?: boolean;
}

export interface Domain {
    domain: string;
}

export class CompanyEntity {
    constructor(data?: SearchCompanyOutputApiModel | InfoCompanyOutputApiModel) {
        if (data instanceof SearchCompanyOutputApiModel) {
            this.convertFromSearchCompanyApi(data);
        }
        if (data instanceof InfoCompanyOutputApiModel) {
            this.convertFromLoadInfoApi(data);
        }
    }

    private convertFromSearchCompanyApi(data: SearchCompanyOutputApiModel) {
        this.id = data.company_id;
        this.name = data.name;
        this.logo = data.avatar;
        this.countAuthors = data.members_count;
        this.background = data.background;
    }

    private convertFromLoadInfoApi(data: InfoCompanyOutputApiModel) {
        this.id = data.id;
        this.name = data.name;
        this.logo = data.logo;
        this.email = data.email;
        this.website = data.website;
        this.show_goodseeker_branding = data.show_goodseeker_branding
        this.countAuthors = data.count_authors;
        this.background = data.background;
        this.domains = data.domains;
        this.enable_public = data.enable_public;
        this.enable_overlay = data.enable_overlay;
        this.default_story_prompt = data.default_story_prompt;
        this.enable_tags = data?.enable_tags

        if (data.buttons) {
            this.buttons = data.buttons.map((button) => {
                return {
                    label: button.label,
                    url: button.url,
                    foreground: button.foreground,
                    background: button.background,
                    isSelected: button.isSelected
                };
            });
        }

        if (data.social_sites) {
            this.socialSites = data.social_sites.map((social_site) => {
                return {
                    name: social_site.name,
                    image: social_site.image,
                    url: social_site.url
                };
            });
        }

        this.countStories = data.count_stories;
        this.countContributors = data.count_contributors;
        this.countCampaigns = data.count_campaigns;
        this.countFeaturedPages = data.count_featured_pages;
        this.slogan = data.slogan;
        this.blurb = data.blurb;
        this.is_logo_updated = data.is_logo_updated;
        this.slug = data.slug;
        this.company_sendgrid_details = data.company_sendgrid_details;
        this.pagePublic = data.page_public;
        this.isInboundStoriesEnabled = data.allow_inbound_stories == 1 ? true : false;
        this.background = data.background;

        if (data.author) {
            this.author = {
                authorId: data.author.author_id,
                avatar: data.author.avatar,
                title: data.author.title,
                displayName: data.author.display_name
            };
        }

        this.frequentTags = data.frequent_tags;
        this.isCampaignCompany = data.is_campaign_company;
        this.showLogoHeader = data.show_logo_header;
        this.internal_stories_approval = data.internal_stories_approval
    }
}
