<div class="story-item" 
     tabindex="0"
     role="article"
     [attr.aria-label]="story.title"
     (mouseover)="changeHoverColor()"
     (mouseout)="resetHoverColor()" 
     [style.backgroundColor]="dynamicHoverColor"  
     [ngClass]="{'inbound': story.type === 'inbound', 'story': story.type != 'inbound'}">
  <div class="story-item__wrap">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="story-item__header" 
             role="heading" 
             aria-level="3"
             [style.background]="GuestLables ? GuestLables : ''" 
             [style.color]="guest_label_text ? guest_label_text : ''"
             *ngIf="story.type === 'inbound' && GuestLables && story?.authorInboundType">
          <div class="blurb">From {{story.authorInboundType}}</div>
        </div>
        <div class="story-item__header" 
             role="heading" 
             aria-level="3"
             [style.background]="userLables ? userLables : ''" 
             [style.color]="user_label_text ? user_label_text : ''"
             *ngIf="story.type != 'inbound' && userLables && story?.authorInboundType">
          <div class="blurb">From {{story.authorInboundType}}</div>
        </div>
      </div>
      <div class="col-lg-2 col-md-3">
        <div data-btn [routerLink]="href" [queryParams]="queryParamsDeatil()" *ngIf="story.media && story.media.length && story.media[0].type.indexOf('video') === -1"
          class="story-item__thumb">
          <img [src]="story.media[0].fullsize == 'https://images.goodseeker.com/goodseeker/mediafiles/company/1602/story-image/f3705dc9-b04e-4ce0-a6ef-ff629a37ed27.png?tr=n-full_size' ? 'https://images.goodseeker.com/goodseeker/mediafiles/company/1602/story-image/c31a3367-581e-4c48-b12d-507a48830c08.png?tr=n-full_size': story.media[0].fullsize " />
        </div>
        <div data-btn 
             [routerLink]="href" 
             [queryParams]="queryParamsDeatil()" 
             *ngIf="story.media && story.media.length && story.media[0].type.indexOf('video') > -1"
             class="story-item__video">
          <img [src]="getUrl(story.media[0])" 
               alt="story.title + 'video thumbnail'" />
        </div>
      </div>
      <div class="col-lg-9 col-md-8 align-self-end">
        <div class="story-item__conts">
          <h2 class="story-item__title" 
              role="heading" 
              aria-level="2" 
              data-btn 
              [routerLink]="href" 
              [queryParams]="queryParamsDeatil()">
            {{story.title}}
          </h2>
          <div class="story-item__info">
            <div class="story-item__user px-0 col-12">
              <gs-avatar [src]="story.author.avatar" 
                         isBackground="true" 
                         classCss="avatar avatar--xs" 
                         alt="'Avatar of' + story.author.displayName">
              </gs-avatar>
              <div class="name text-nowrap">{{story.author.displayName}}</div>
            </div>
          </div>
          <div class="story-item__tags  d-lg-block" *ngIf="story.highlightTags">
            <a *ngFor="let tag of story.highlightTags" 
               href="javascript:void(0)" 
               class="tag" 
               [style.color]="tag.color" 
               role="link" 
               [attr.aria-label]="'Tag:' + tag.text">
              #{{tag.text}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="story-item__detail" 
       tabindex="0"
       (mouseover)="changeHoverColor()"
       (mouseout)="resetHoverColor()" 
       [style.backgroundColor]="dynamicHoverColor">
    <a [routerLink]="href" 
       [queryParams]="queryParamsDeatil()" 
       [attr.aria-label]="'Read more about ' +  story.title">
      <div class="story-item__detail-wrap">
        <div class="story-item__detail-desc" 
             *ngIf="story.media && story.media.length">
          <p [innerText]="story.content | stripHtml"></p>
        </div>
        <div class="story-item__detail-player" 
             *ngIf="story.contributors">
          <div class="player-item" *ngFor="let player of story.contributors">
            <div class="player-item__avatar">
              <gs-avatar [src]="player.avatar" 
                         [title]="player.displayName" 
                         isBackground="true" 
                         classCss="avatar avatar--md" 
                         alt="player.displayName + 'avatar'">
              </gs-avatar>
            </div>
          </div>
        </div>
        <div class="story-item__detail-more">
          <a [attr.aria-label]="'Read more about' +  story.title" class="btn btn-white btn-md btn-outline">Read More</a>
        </div>
      </div>
    </a>
  </div>
</div>
