<div class="form-inline filter_data" [formGroup]="filterForm">
  <div class="form-group  mr-2" *ngFor="let filter of options.filters">
    <label class="text-small mr-2 ml-1">{{filter.label}}</label>
    <select *ngIf="columnType.select === filter.type" (change)="getGridvalue($event)" [formControlName]="filter.name" class="custom-select mr-2">
      <option *ngFor="let option of filter.selections" [value]="option[filter.valueField]">
        {{option[filter.displayField]}}
      </option>
    </select>
    <gs-input-date-range *ngIf="columnType.daterange === filter.type" [placeholder]="filter.placeholder"
      [formControlName]="filter.name"></gs-input-date-range>
  </div>
  <ng-container *ngIf="showSearchbar">
    <!--<div class="spacer"></div>-->
    <div class="form-group mb-0">
      <div class="search-input">
        <input formControlName="searchText" type="text" placeholder="Search" class="form-control admin-search">
      </div>
    </div>
  </ng-container>

</div>