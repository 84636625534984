import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'image-lazyload-control',
    templateUrl: './image-lazyload-control.component.html',
    styleUrls: ['./image-lazyload-control.component.scss']
})
export class ImageLazyloadControlComponent implements OnInit, OnChanges {
    @Input() imgSrcInput: string;
    imgSrc: string;
    RetryInterval;
    isRetrying: boolean = false;
    isError: boolean = false;
    isImageLoaded: boolean = false;

    constructor(private detector: ChangeDetectorRef) {
        detector.detach();
    }

    ngOnInit(): void { }

    ngOnChanges() {
        this.imgSrc = this.imgSrcInput;
        this.isImageLoaded = false;
        this.isRetrying = true;
        this.isError = false;
        this.detector.detectChanges();
    }

    public imgOnload(e) {
        this.isImageLoaded = true;
        this.isRetrying = false;
        this.isError = false;
        if (this.RetryInterval) {
            clearInterval(this.RetryInterval);
            this.RetryInterval = null;
        }
        this.detector.detectChanges();
    }

    public waitAndReload(event) {
        // //console.log('Image loading failed!');
        if (!this.RetryInterval) {
            const originalSrc = event.target.src;
            this.RetryInterval = setInterval(() => {
                if (parseInt(event.target.getAttribute('data-retry'), 10) !== parseInt(event.target.getAttribute('data-max-retry'), 10)) {
                    //console.log('Retrying', parseInt(event.target.getAttribute('data-retry'), 10));
                    event.target.setAttribute('data-retry', parseInt(event.target.getAttribute('data-retry'), 10) + 1);
                    event.target.src = originalSrc;
                    this.isImageLoaded = false;
                    this.isRetrying = true;
                    this.isError = false;
                    this.detector.detectChanges();
                } else {
                    event.target.src = '';
                    this.isImageLoaded = false;
                    this.isRetrying = false;
                    this.isError = true;
                    this.detector.detectChanges();
                }
            }, 2000);
        }
    }
}
