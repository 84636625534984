<div class="modal-header pb-0">
  <h4 class="modal-title">Consent Form</h4>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body" [formGroup]="consentForm">
  <div class="template_html">
    <div [innerHtml]="template"></div>
  </div>
  <div class="option_body">
    <h6 *ngIf="show_name">
      My Name <span class="madatory">*</span>
    </h6>
    <div class="row mb-4" *ngIf="show_name">
      <div class="col-12" *ngIf="responseData?.is_real_name">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_name" value="is_real_name" class="custom-control-input">
            <span class="custom-control-label">Full Name can be used</span>
          </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_first_name">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_name" value="is_first_name" class="custom-control-input">
            <span class="custom-control-label">First Name only can be used</span>
          </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_initials_name">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_name" value="is_initials_name" class="custom-control-input">
            <span class="custom-control-label">Initials only can be used</span>
          </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_fake_name">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_name" value="is_fake_name" class="custom-control-input">
            <span class="custom-control-label">Fake First Name only</span>
          </label>
        </div>
      </div>
    </div>
    <h6 *ngIf="show_image">
      My Image and Likeness<span class="madatory">*</span>
    </h6>
    <div class="row mb-4" *ngIf="show_image">
      <div class="col-12" *ngIf="responseData?.is_image_in_any_media">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_image" value="is_image_in_any_media" class="custom-control-input">
            <span class="custom-control-label">I allow my image or likeness in any media</span>
          </label>
        </div>
      </div>
      <!--<div class="col-12"  *ngIf="responseData?.is_video_use">
    <div class="custom-control custom-radio">
        <label class="mb-0">
            <input type="radio" formControlName="from_image" value="is_video_use"
                class="custom-control-input">
            <span class="custom-control-label">I allow my image or likeness in video only</span>
        </label>
    </div>
  </div>-->
      <!--<div class="col-12" *ngIf="responseData?.is_image_social_media">
    <div class="custom-control custom-radio">
        <label class="mb-0">
            <input type="radio" formControlName="from_image" value="is_image_social_media"
                class="custom-control-input">
            <span class="custom-control-label">I allow my image or likeness in social media only</span>
        </label>
    </div>
  </div>
 <div class="col-12" *ngIf="responseData?.is_full_image">
    <div class="custom-control custom-radio">
        <label class="mb-0">
            <input type="radio" formControlName="from_image" value="is_full_image"
                class="custom-control-input">
            <span class="custom-control-label">I allow my image or likeness in all media</span>
        </label>
    </div>
  </div>-->
      <div class="col-12" *ngIf="responseData?.is_blurred_image">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_image" value="is_blurred_image" class="custom-control-input">
            <span class="custom-control-label">My image or likeness must be blurred or hidden</span>
          </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.not_use_image">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_image" value="not_use_image" class="custom-control-input">
            <span class="custom-control-label">Do not use my image or likeness</span>
          </label>
        </div>
      </div>
    </div>
    <h6 *ngIf="show_voice">
      My Voice <span class="madatory">*</span>
    </h6>
    <div class="row mb-4" *ngIf="show_voice">
      <div class="col-12" *ngIf="responseData?.is_real_voice">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_voice" value="is_real_voice" class="custom-control-input">
            <span class="custom-control-label">My voice can be used</span>
          </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_masked_voice">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_voice" value="is_masked_voice" class="custom-control-input">
            <span class="custom-control-label">My voice must be masked or adjusted</span>
          </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_voice_transcription">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_voice" value="is_voice_transcription"
              class="custom-control-input">
            <span class="custom-control-label">Do not use my voice, but transcription is allowed</span>
          </label>
        </div>
      </div>
    </div>

    <h6 *ngIf="show_other">
      Other Uses
    </h6>
    <div class="row mb-4" *ngIf="show_other">
      <div class="col-12" *ngIf="responseData?.is_name_advertising">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_other" value="is_name_advertising" class="custom-control-input">
            <span class="custom-control-label">I allow my name to be used in advertising</span>
          </label>
        </div>
      </div>
      <div class="col-12" *ngIf="responseData?.is_image_media_advertising">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="from_other" value="is_image_media_advertising"
              class="custom-control-input">
            <span class="custom-control-label">I allow my image or likeness to be used in advertising</span>
          </label>
        </div>
      </div>
    </div>
    <div class="sign_container" *ngIf="signatureNeeded === false">
      <img src="{{ signatureImg }}" alt="" srcset="" />

    </div>
    <h5>Signature Here <span class="madatory">*</span></h5>
    <div class="signature_content mt-3">

      <div class="row">
        <div class="col-2 mr-2">
          <div class="custom-control custom-radio">
            <label class="mb-0">
              <input type="radio" formControlName="sign_type" (change)="onSignTypeChange($event)" value="type"
                class="custom-control-input">
              <span class="custom-control-label">Type</span>
            </label>
          </div>
        </div>
        <div class="col-2 mr-2">
          <div class="custom-control custom-radio">
            <label class="mb-0">
              <input type="radio" formControlName="sign_type" (change)="onSignTypeChange($event)" value="draw"
                class="custom-control-input">
              <span class="custom-control-label">Draw</span>
            </label>
          </div>
        </div>
        <div class="col-2 mr-2">
          <div class="custom-control custom-radio">
            <label class="mb-0">
              <input type="radio" formControlName="sign_type" (change)="onSignTypeChange($event)" value="upload"
                class="custom-control-input">
              <span class="custom-control-label">Upload</span>
            </label>
          </div>
        </div>
      </div>

      <div class="mt-3" *ngIf="consentForm.controls.sign_type.value == 'type' ">
        <gs-input-material label="Type your name" name="Type your name" placeholder="Type your name"
          formControlName="esignature" [formControl]="consentForm.controls.esignature" type="text">
        </gs-input-material></div>


      <div class="upload_sign mt-3"
      *ngIf="consentForm.controls.sign_type.value == 'upload' ">
      <img *ngIf="signatureImg" [src]="signatureImg" style="height: 100px; width: 300px;" alt="">
      <div class="upload-box" (click)="fileInput.click()" *ngIf="!signatureImg">
        <input type="file" #fileInput (change)="onFileSelected($event)" hidden />
        <div class="upload-content">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
          style="    height: 100px;
          width: 300px;"
            stroke="currentColor" class="upload-icon">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M3 16.5V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18v-1.5m-9 0V5.25m0 0L6.75 9m4.5-3.75l4.5 3.75" />
          </svg> -->
          <i class="fa-light fa-upload"></i>
          <!-- <p>Click to upload</p> -->
        </div>
      </div>

    </div>
    <div class="draw_sign mt-3"  *ngIf="consentForm.controls.sign_type.value == 'draw' " [ngStyle]="{'visibility':consentForm.controls.sign_type.value != 'draw' ? 'hidden': 'visible' }">
    <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" width="300" height="100"
      style="border: 1px solid #b0bec6; border-radius: 5px;background-color: #f2f2f2;"></canvas>
    <p class="error-message" *ngIf="signatureNeeded">Signature is required</p>

    <div [ngStyle]="{'visibility':consentForm.controls.sign_type.value != 'draw' ? 'hidden': 'visible' }"
      class="buttons flex justify-between mt-2">
      <button class="btn" (click)="clearPad()">Clear</button>
      <button class="btn" (click)="savePad()">Apply Signature</button>
    </div>
  </div>


</div>
</div>
<div class="modal-footer">
  <div>
    <button (click)="close()" type="button" data-dismiss="modal"
      class="btn btn-outline btn-gray-light min-width">Cancel</button>
    <button (click)="submitForm()" [disabled]="consentForm.invalid" type="button" class="btn btn-pink min-width">
      <i *ngIf="isUpdating" class="fas fa-spinner fa-pulse"></i>
      Save
    </button>
  </div>

</div>
</div>


<!-- <iframe src="http://localhost:4200/?&showHeader=true&showtagheader=true" height="900px" width="100px" frameborder="0"></iframe> -->