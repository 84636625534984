<div class="modal-header pb-2">
  <h4 class="modal-title">{{ activeTab == 'embedCode' ? 'Embed Public Stories' : 'Embed Options'}}</h4>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body">

  <ul role="tablist" class="nav nav-tabs tabs-icon mb-4">
    <li class="nav-item" [style.backgroundColor]="activeTab == 'iframe' ? navigation_color : ''"  [style.color]="activeTab == 'iframe' ? '#fff' : '#78909c'">
      <a href="javascript:void(0)" [style.border-color]="activeTab == 'iframe' ? navigation_color : ''" (click)="activateTab('iframe')" [style.color]="activeTab == 'iframe' ? '#fff' : '#78909c'" role="tab" class="nav-link"
       >
        <span class="text">Feed iFrame</span></a>
    </li>
    <li class="nav-item" [style.backgroundColor]="activeTab == 'embedCode' ? navigation_color : ''" [style.color]="activeTab == 'embedCode' ? '#fff' : '#78909c'">
      <a href="javascript:void(0)" [style.border-color]="activeTab == 'embedCode' ? navigation_color : ''" (click)="activateTab('embedCode')" [style.color]="activeTab == 'embedCode' ? '#fff' : '#78909c'" role="tab" class="nav-link"
        >
        <span class="text">Feed Script</span></a>
    </li>

    <li class="nav-item" [style.backgroundColor]="activeTab == 'RSS' ? navigation_color : ''" [style.color]="activeTab == 'RSS' ? '#fff' : '#78909c'">
      <a href="javascript:void(0)" [style.border-color]="activeTab == 'RSS' ? navigation_color : ''" (click)="activateTab('RSS')" [style.color]="activeTab == 'RSS' ? '#fff' : '#78909c'" role="tab" class="nav-link"
>
        <span class="text">Feed RSS</span></a>
    </li>
    <li class="nav-item"  [style.backgroundColor]="activeTab == 'embedButton' ? navigation_color : ''" [style.color]="activeTab == 'embedButton' ? '#fff' : '#78909c'"*ngIf="initialState.sendData?.from == 'company' || initialState.sendData?.from == 'campaign'">
      <a href="javascript:void(0)" [style.border-color]="activeTab == 'embedButton' ? navigation_color : ''" (click)="activateTab('embedButton')" [style.color]="activeTab == 'embedButton' ? '#fff' : '#78909c'" role="tab" class="nav-link"
        >
        <span class="text">Submit Story Button</span></a>
    </li>
  </ul>

  <div [formGroup]="embedForm" *ngIf="activeTab == 'embedCode'">
    <div class="option-wrapper embed-sub-wrapper mb-2 p-3" *ngIf="!IsScriptCreated">
      <h6>Display Story Elements</h6>
      <div class="row mt-3">
        <div class="col-md-2">
          <div>
            <label class="switch mr-1">
              <input type="checkbox" formControlName="isTitle"><span class="slider"></span>
            </label>
            <div class="w-50-p d-inline-block">
              Title
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div>
            <label class="switch mr-1">
              <input type="checkbox" formControlName="isAuthor"><span class="slider"></span>
            </label>
            <div class="w-50-p d-inline-block">
              Author
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div>
            <label class="switch mr-1">
              <input type="checkbox" formControlName="isDate"><span class="slider"></span>
            </label>
            <div class="w-50-p d-inline-block">
              Date
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div>
            <label class="switch mr-1">
              <input type="checkbox" formControlName="isTags"><span class="slider"></span>
            </label>
            <div class="w-50-p d-inline-block">
              Tags
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div>
            <label class="switch mr-1">
              <input type="checkbox" formControlName="isCta"><span class="slider"></span>
            </label>
            <div class="w-50-p d-inline-block">
              CTA
            </div>
          </div>
        </div>
        <!-- <div class="col-md-2">
          <div>
            <label class="switch mr-1">
              <input type="checkbox" formControlName="isImage"><span class="slider"></span>
            </label>
            <div class="w-50-p d-inline-block">
              Image
            </div>
          </div>
        </div> -->
        <!-- <div class="col-md-2">
          <div>
            <label class="switch mr-1">
              <input type="checkbox" formControlName="isBody"><span class="slider"></span>
            </label>
            <div class="w-50-p d-inline-block">
              Body
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="color-wrapper embed-sub-wrapper mb-0 px-3 pb-0 pt-3" *ngIf="!IsScriptCreated">
      <h6>Embed Colors</h6>
      <div class="row mb-1">
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="backgroundColor" [formControl]="embedForm.controls.backgroundColor"
            label="Background" name="Background" type="text"></gs-input-color-picker>
        </div>
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="foregroundColor" [formControl]="embedForm.controls.foregroundColor"
            label="Hover" name="Hover Background" type="text"></gs-input-color-picker>
        </div>
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="highlightColor" [formControl]="embedForm.controls.highlightColor"
            label="Button Hover" name="Button Hover" type="text"></gs-input-color-picker>
        </div>
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="border_color" [formControl]="embedForm.controls.border_color"
            label="Border" name="Border" type="text"></gs-input-color-picker>
        </div>
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="user_labels" [formControl]="embedForm.controls.user_labels"
            label="User Label" name="Border" type="text"></gs-input-color-picker>
        </div>
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="guest_label" [formControl]="embedForm.controls.guest_label"
            label="Guest Label" name="Guestlabel" type="text"></gs-input-color-picker>
        </div>
      </div>  
      <div class="row mb-2">
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="title_color" [formControl]="embedForm.controls.title_color"
            label="Title Text" name="Title Text" type="text"></gs-input-color-picker>
        </div>
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="font_color_hover" [formControl]="embedForm.controls.font_color_hover"
            label="Body Text" name="Body Text" type="text"></gs-input-color-picker>
        </div>
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="hover_text" [formControl]="embedForm.controls.hover_text"
            label="Button Text" name="Button Text" type="text"></gs-input-color-picker>
        </div>
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="body_color" [formControl]="embedForm.controls.body_color"
            label="Author Text" name="Author Text" type="text"></gs-input-color-picker>
        </div>
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="user_label_background" [formControl]="embedForm.controls.user_label_background"
            label="User Label Text" name="User Label text" type="text"></gs-input-color-picker>
        </div>
        <div class="col-2 extnd_width">
          <gs-input-color-picker formControlName="guest_label_background" [formControl]="embedForm.controls.guest_label_background"
            label="Guest  Text" name="Label text" type="text"></gs-input-color-picker>
        </div>
      </div>
      


      <h6>Embed Fonts</h6>
      <div class="row mb-2">
        <div class="col-3">
          <gs-input-material formControlName="headline_font_size" [formControl]="embedForm.controls.headline_font_size" label="Title Font (px)"
          type="text">
        </gs-input-material>
        </div>
        <div class="col-3">
          <gs-input-material formControlName="body_font_size" [formControl]="embedForm.controls.body_font_size" label="Body Font (px)"
          type="text">
        </gs-input-material>
        </div>
        <div class="col-3">
          <gs-input-material formControlName="hover_button" [formControl]="embedForm.controls.hover_button" label="Button Label"
          type="text">
        </gs-input-material>
        </div>
        <div class="col-3">
          <gs-input-material formControlName="border_radius" [formControl]="embedForm.controls.border_radius" label="Border Radius (px)"
          type="text">
        </gs-input-material>
        </div>
      </div>

      <!--<h6>Embed Tile Radius</h6>
      <div class="row">
        <div class="col-3">
          <gs-input-material formControlName="border_radius" [formControl]="embedForm.controls.border_radius" label="Border Radius"
          type="text">
        </gs-input-material>
        </div>
      </div>-->
    </div>

    <div class="radio-wrapper embed-sub-wrapper mb-3 p-3" *ngIf="!IsScriptCreated">
      <div class="row">
        <div class="col-6">
          <h6>Embed Layout</h6>
          <div class="row">
            <div class="col-4">
              <div class="custom-control custom-radio">
                <label class="mb-0">
                  <input type="radio" formControlName="selectRadio" value="tile" class="custom-control-input">
                  <span class="custom-control-label"> Grid</span>
                </label>
              </div>
            </div>
            <!-- <div class="col-4">
              <div class="custom-control custom-radio">
                <label class="mb-0">
                  <input type="radio" formControlName="selectRadio" value="list" class="custom-control-input">
                  <span class="custom-control-label"> List</span>
                </label>
              </div>
            </div> -->
            <div class="col-4">
              <div class="custom-control custom-radio">
                <label class="mb-0">
                  <input type="radio" formControlName="selectRadio" value="craousel" class="custom-control-input">
                  <span class="custom-control-label">Carousel</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <h6>Open Story As</h6>
          <div class="row">
            <div class="col-6">
              <div class="custom-control custom-radio">
                <label class="mb-0">
                  <input type="radio" formControlName="openToRadio" value="modalWindow" class="custom-control-input">
                  <span class="custom-control-label">Modal Window</span>
                </label>
              </div>
            </div>
            <div class="col-6">
              <div class="custom-control custom-radio">
                <label class="mb-0">
                  <input type="radio" formControlName="openToRadio" value="newWindow" class="custom-control-input">
                  <span class="custom-control-label">New Window</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="embedForm.controls.selectRadio.value == 'craousel'">
        <div class="col-6">
          <gs-input-material formControlName="number_of_items" [formControl]="embedForm.controls.number_of_items" label="Number Of Items Per Slide"
          type="text">
        </gs-input-material>
        </div>
      </div>
    </div>

    <div class="embed_tab" *ngIf="!IsScriptCreated">
      <mat-accordion [multi]="false">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> <span>Custom CSS</span></mat-panel-title>
          </mat-expansion-panel-header>
       <div class="row">
        <div class="col">
          <gs-area-material
          label="" name="Add CSS" placeholder="" rows="10"
          cssClass="spacing-lg" formControlName="custom_css" [formControl]="embedForm.controls.custom_css">
      </gs-area-material>
        </div>
       </div>

  
        </mat-expansion-panel>
  
      </mat-accordion>
    </div>

    <div class="lines-wrapper embed-sub-wrapper mb-4 px-3 pb-0 pt-3" *ngIf="!IsScriptCreated">
      <div class="row">
        <!-- <div class="col-6">
          <gs-input-material formControlName="lineCount" [formControl]="embedForm.controls.lineCount"
            label="Lines of Story Content" type="number">
          </gs-input-material>
        </div> -->
        <!-- <div class="col-6">
          <h6>Which Stories</h6>
          <div class="row">
            <div class="col-4">
              <div class="custom-control custom-checkbox">
                <label class="mb-0">
                  <input type="checkbox" formControlName="selectCheckbox" value="public" class="custom-control-input">
                  <span class="custom-control-label">Public</span>
                </label>
              </div>
            </div>
            <div class="col-8">
              <div class="custom-control custom-checkbox">
                <label class="mb-0">
                  <input type="checkbox" value="private" class="custom-control-input" disabled>
                  <span class="custom-control-label">Private (coming soon)</span>
                </label>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div style="display: flex; justify-content: center;" >
      <div  class="button-wrapper text-center mr-2 mb-4" *ngIf="!IsScriptCreated && feedType != 'POST' ">
        <button style="width:8rem" [disabled]="disableSavButton" (click)="getScript(true)" type="button" class="btn btn-pink min-width">
          <i
          *ngIf="isUpdating" class="fas fa-spinner fa-pulse"></i>
        {{buttonlabel}}
        </button>
      </div>
  
      <div class="button-wrapper text-center" *ngIf="!IsScriptCreated">
        <button (click)="getScript(false)" type="button" class="btn btn-pink min-width">
          Get Script
        </button>
      </div>
    </div>



    <div class="code-wrapper embed-form-disabled" *ngIf="IsScriptCreated && activeTab == 'embedCode'">
      <h6>Place the below code inside your <span>&lt;body&gt;</span> tag.</h6>
      <div class="form-label-group form-group-copy nolabel">
        <gs-input-clipboard name="bodyCode" placeholder="Body Code" formControlName="bodyCode"
          [formControl]="embedForm.controls.bodyCode"></gs-input-clipboard>
      </div>
      <h6>Place the below code just before <span>&lt;/body&gt;</span> tag.</h6>
      <div class="form-label-group form-group-copy nolabel">
        <gs-textarea-clipboard rows="6" name="headerCode" placeholder="Header Code" formControlName="headerCode"
          [formControl]="embedForm.controls.headerCode"></gs-textarea-clipboard>
      </div>
      <h6>You can use these classes to override the CSS under <span>&lt;style&gt;</span> tag.</h6>
      <div class="form-label-group form-group-copy nolabel">
        <gs-textarea-clipboard rows="8" name="styleCode" placeholder="Style" formControlName="styleCode"
          [formControl]="embedForm.controls.styleCode"></gs-textarea-clipboard>
      </div>
    </div>
  </div>




  <div class="color-wrapper embed-sub-wrapper mb-4 px-3 pb-0 pt-3" *ngIf="activeTab == 'iframe'"
    [formGroup]="iFrameForm">
    <div class="row mt-0">
      <div class="col-3 iFrame-value px-3">
        <h6>Which Page</h6>
      </div>
      <div class="col-2">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="selectCheckbox" value="public" class="custom-control-input">
            <span class="custom-control-label">Public</span>
          </label>
        </div>
      </div>
      <div class="col-2">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="selectCheckbox" value="private" class="custom-control-input">
            <span class="custom-control-label">Private</span>
          </label>
        </div>
      </div>
    </div>
 



    <div class="row mt-3">
      <div class="col-3 iFrame-value px-3">
        <h6>Display Page Header</h6>
      </div>
      <div class="col-4">
        <div class="custom-control custom-checkbox">
          <label class="mb-0">
            <input type="checkbox" formControlName="showHeader" class="custom-control-input">
            <span class="custom-control-label"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-3 iFrame-value px-3">
        <h6>Display Tag Summary</h6>
      </div>
      <div class="col-4">
        <div class="custom-control custom-checkbox">
          <label class="mb-0">
            <input type="checkbox" formControlName="showtagheader" class="custom-control-input">
            <span class="custom-control-label"></span>
          </label>
        </div>
      </div>
    </div>
    
    <div class="row mt-3" *ngIf="initialState.sendData.from == 'company'">
      <div class="col-3 iFrame-value px-3">
        <h6>Display Nav Items</h6>
      </div>
      <div class="col-2 titleDispaly">
        <div class="custom-control custom-checkbox" [ngClass]="{'isClickable':!iFrameForm.value.isCampaigns && !iFrameForm.value.isStories}">
          <label class="mb-0 ml-0">
            <input type="checkbox"   formControlName="isSpotlight" class="custom-control-input">
            <span class="custom-control-label"></span>
          </label>
        </div>
       <span class="mt-1">Spotlights</span> 
      </div>
      <div class="col-2 titleDispaly">
        <div class="custom-control custom-checkbox" [ngClass]="{'isClickable':!iFrameForm.value.isSpotlight && !iFrameForm.value.isStories}">
          <label class="mb-0 ml-3">
            <input type="checkbox" [disabled]="!iFrameForm.value.isSpotlight && !iFrameForm.value.isStories" formControlName="isCampaigns" class="custom-control-input">
            <span class="custom-control-label"></span>
          </label>
        </div>
         <span class="mt-1" >Campaigns</span> 

      </div>
      <div class="col-2 titleDispaly">
        <div class="custom-control custom-checkbox" [ngClass]="{'isClickable':!iFrameForm.value.isSpotlight && !iFrameForm.value.isCampaigns}">
          <label class="mb-0 ml-3" >
            <input type="checkbox"  formControlName="isStories" class="custom-control-input">
            <span class="custom-control-label"></span>
          </label>
        </div>
        <span class="mt-1" >All</span> 
      </div>
    </div>

    <div class="row mt-3 mb-2" *ngIf="iFrameForm.controls.selectCheckbox.value == 'public'">
    <div class="col-3 iFrame-value px-3">
      <h6>Display Public Footer</h6>
    </div>
      <div class="col-md-2">
        <div>
          <label class="switch mr-1">
            <input type="checkbox" formControlName="isFooter"><span class="slider"></span>
          </label>
          <div class="w-50-p d-inline-block">
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3 mb-2">
      <div class="col-3 iFrame-value px-3">
        <h6>Display Border</h6>
      </div>
      <div class="col-4">
        <div class="custom-control custom-checkbox">
          <label class="mb-0">
            <input type="checkbox" formControlName="selectRadio" class="custom-control-input">
            <span class="custom-control-label"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 iFrame-value px-3">
        <h6>iFrame Height</h6>
      </div>
      <div class="col-lg-2">
        <gs-input-material formControlName="height" [formControl]="iFrameForm.controls.height" label="height"
          type="number">
        </gs-input-material>
      </div>
      <div class="col iFrame-value">
        <!-- <h6>Px</h6> -->
        <select formControlName="heightList" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 iFrame-value px-3">
        <h6>iFrame Width</h6>
      </div>
      <div class="col-lg-2">
        <gs-input-material formControlName="width" [formControl]="iFrameForm.controls.width" label="Width"
          type="number">
        </gs-input-material>
      </div>
      <div class="col iFrame-value">
        <select formControlName="widthList" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
    </div>

    <div class="code-wrapper embed-form-disabled mb-4">
      <h6 class="mt-4 mb-3">Place the below code inside your <span>&lt;body&gt;</span> tag. {{updatedIframeCode}}</h6>
      <div class="form-label-group form-group-copy nolabel">
        <gs-input-clipboard name="bodyCode" placeholder="Body Code" formControlName="bodyCode"
          [formControl]="iFrameForm.controls.bodyCode"></gs-input-clipboard>
      </div>
    </div>
  </div>

  <div class="color-wrapper embed-sub-wrapper mb-4 px-3 pb-0 pt-3" *ngIf="activeTab == 'RSS'"
  [formGroup]="rssForm">
  <div class="code-wrapper embed-form-disabled">
    <h6 class="mt-4 mb-3">COPY RSS LINK (INTERNAL AND PUBLIC) &nbsp; <span class="svgForRss"><svg role="img">
      <use xlink:href="#icon-rss"></use>
    </svg></span></h6>
    <div class="form-label-group form-group-copy nolabel">
      <gs-input-clipboard name="LINK" placeholder="LINK" formControlName="value"
        [formControl]="rssForm.controls.value"></gs-input-clipboard>
    </div>
  </div>
  <div class="code-wrapper embed-form-disabled">
    <h6 class="mt-4 mb-3">COPY RSS LINK (PUBLIC ONLY) &nbsp; <span class="svgForRss"><svg role="img">
      <use xlink:href="#icon-rss"></use>
    </svg></span></h6>
    <div class="form-label-group form-group-copy nolabel">
      <gs-input-clipboard name="LINK" placeholder="LINK" [disabled]="true" formControlName="publicValue"
        [formControl]="rssForm.controls.publicValue"></gs-input-clipboard>
    </div>
  </div>
</div>

<div class="color-wrapper embed-sub-wrapper mb-4 px-3 pb-0 pt-3" [formGroup]="embedButtonForm"  *ngIf="activeTab == 'embedButton'">
  <div class="embed-btn" *ngIf="!IsScriptCreated">
    <div class="row mb-4">
      <div class="col-3"><h6>Open Story Builder In:</h6></div>
      <div class="col-4" style="padding-left: 2rem;">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="selectRadio" value="_self" class="custom-control-input">
            <span class="custom-control-label">Current Tab</span>
          </label>
        </div>
      </div>
      <div class="col-4" style="padding-left: 0rem;">              
        <div class="custom-control custom-radio">
        <label class="mb-0">
          <input type="radio" formControlName="selectRadio" value="_blank" class="custom-control-input">
          <span class="custom-control-label">Popup Window</span>
        </label>
      </div>
    </div>
    </div>
    <div class="row">
      <div class="col-3">
        <!--<h6>Button Text</h6>-->
        <gs-input-material type="text" label="Button Label" formControlName="buttonText" [formControl]="embedButtonForm.controls.buttonText" class="btn-text">
        </gs-input-material>
      </div>
      <div class="col-3">
        <gs-input-color-picker formControlName="backgroundColor" [formControl]="embedButtonForm.controls.backgroundColor"
        label="Button Background" name="BackgroundColor" type="text"></gs-input-color-picker>
        <!-- <gs-input-color-picker  formControlName="backgroundColor" [formControl]="embedButtonForm.controls.backgroundColor" label="Background" name="BackgroundColor" type="text"></gs-input-color-picker> -->
    </div>
    <div class="col-3">
      <!-- <gs-input-color-picker  formControlName="textColor" [formControl]="embedButtonForm.controls.textColor" label="Text Color" name="Textcolor" type="text"></gs-input-color-picker> -->
      <gs-input-color-picker formControlName="textColor" [formControl]="embedButtonForm.controls.textColor"
      label="Button Text" name="TextColor" type="text"></gs-input-color-picker>
    </div>
    </div>
    
    <div class="row mt-0">
      <div class="col-3">
        <gs-input-material formControlName="fontsize" [formControl]="embedButtonForm.controls.fontsize" label="Font Size (px)"
          type="number">
        </gs-input-material>
      </div>

      <!--<div class="col-3">
        <gs-input-material formControlName="text_align" [formControl]="embedButtonForm.controls.text_align" label="Text Align"
          type="text">
        </gs-input-material>
      </div> -->
      <div class="col-3 form-label-group embed_testimonial">
        <input type="number" formControlName="width"  class="form-control">
        <label>Button Width (px)</label>     
       </div>
     <!-- <div class="col-1 iFrame-value">
        <select formControlName="widthList" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>-->
      <div class="col-3 form-label-group embed_testimonial">
        <input type="number" formControlName="height"  class="form-control">
        <label>Button Height (px)</label>     
       </div>
      <!--<div class="col-1 iFrame-value">
        <select formControlName="heightList" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>-->
    </div>
    <div class="row">
      <!--<div class="col-3">
        <gs-input-material formControlName="text_align" [formControl]="embedButtonForm.controls.text_align" label="Text Align"
          type="text">
        </gs-input-material>
      </div> 
      <div class="col-3 ml-7">
        <gs-input-material formControlName="border_radius" [formControl]="embedButtonForm.controls.border_radius" label="Border Radius (px)"
          type="number">
        </gs-input-material>
      </div>-->
      <!--<div class="col-1 iFrame-value">
        <select formControlName="bordervalue" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>-->   
    </div>
    <div class="row">
      <div class="col-3">
        <gs-input-material formControlName="paddingbottom" [formControl]="embedButtonForm.controls.paddingbottom" label="Padding bottom (px)"
        type="text">
      </gs-input-material>
      </div>
      <div class="col-3">
        <gs-input-material formControlName="paddingleft" [formControl]="embedButtonForm.controls.paddingleft" label="Padding left (px)"
        type="text">
      </gs-input-material>
      </div>
      <div class="col-3">
        <gs-input-material formControlName="paddingright" [formControl]="embedButtonForm.controls.paddingright" label="Padding right (px)"
        type="text">
      </gs-input-material>
      </div>
      <div class="col-3">
        <gs-input-material formControlName="paddingtop" [formControl]="embedButtonForm.controls.paddingtop" label="Padding top (px)"
        type="text">
      </gs-input-material>
      </div>
    </div>
    <div class="embed_tab">
      <mat-accordion [multi]="false">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> <span>Custom CSS</span></mat-panel-title>
          </mat-expansion-panel-header>
       <div class="row">
        <div class="col">
          <gs-area-material
          label="" name="Add CSS" placeholder="" rows="10"
          cssClass="spacing-lg" formControlName="customStyleCode" [formControl]="embedButtonForm.controls.customStyleCode">
      </gs-area-material>
        </div>
       </div>

  
        </mat-expansion-panel>
  
      </mat-accordion>
    </div>


  </div>


  <div class="option-wrapper embed-sub-wrapper mb-4 mt-4 p-3 text-center" *ngIf="!IsScriptCreated">
    <div class="row">
      <div class="col-12">
        <div [id]="initialState.sendData.paraentDiv">
          <button type="button" id="embed_btn" target="_self" href="javascript:void(0);" >
            {{embedButtonForm.controls.buttonText.value}}
          </button>
        </div>

      </div>
    </div>
  </div>

  <div class="button-wrapper text-center" *ngIf="!IsScriptCreated">
    <button (click)="getEmbedButtonScript()" [disabled]="embedButtonForm.invalid" type="button" class="btn btn-pink min-width">
      Get Script
    </button>
  </div>

  <div class="code-wrapper embed-form-disabled" *ngIf="IsScriptCreated">
    <h6>Place the below code inside your <span>&lt;body&gt;</span> tag.</h6>
    <div class="form-label-group form-group-copy nolabel">
      <gs-input-clipboard name="bodyCode" placeholder="Body Code" formControlName="bodyCode"
        [formControl]="embedButtonForm.controls.bodyCode"></gs-input-clipboard>
    </div>
    <h6>Place the below code just before <span>&lt;/body&gt;</span> tag.</h6>
    <div class="form-label-group form-group-copy nolabel">
      <gs-textarea-clipboard rows="6" name="headerCode" placeholder="Header Code" formControlName="headerCode"
        [formControl]="embedButtonForm.controls.headerCode"></gs-textarea-clipboard>
    </div>

  </div>
</div>
</div>

<!-- <iframe src="http://localhost:4200/?&showHeader=true&showtagheader=true" height="900px" width="100px" frameborder="0"></iframe> -->