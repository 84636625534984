<div class="story-item" (mouseover)="changeHoverColor()"
     (mouseout)="resetHoverColor()" 
     [style.backgroundColor]="dynamicHoverColor" 
     [ngClass]="{'inbound': story.type === 'inbound', 'story': story.type != 'inbound'}"
     tabindex="0" role="article" [attr.aria-labelledby]="'story-title-' + story.id">
  <div class="story-item__wrap">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="story-item__header" [style.background]="guestLabels ? guestLabels : ''" 
        [style.color]="guest_label_text ? guest_label_text : ''"
             *ngIf="story.type === 'inbound' && story?.authorInboundType && guestLabels">
          <div class="blurb">From {{story.authorInboundType}}</div>
        </div>
        <div class="story-item__header" [style.background]="userLabels ? userLabels : ''" 
        [style.color]="user_label_text ? user_label_text : ''"
             *ngIf="story.type != 'inbound' && story?.authorInboundType && userLabels">
          <div class="blurb">From {{story.authorInboundType}}</div>
        </div>
      </div>
      <div class="col-lg-2 col-md-3">
        <div data-btn [routerLink]="href" [queryParams]="{public:1}" *ngIf="story.media && story.media.length && story.media[0].type.indexOf('video') === -1"
          class="story-item__thumb">
          <img (load)="imgOnload($event)" [src]="story.media[0].fullsize == 'https://images.goodseeker.com/goodseeker/mediafiles/company/1602/story-image/f3705dc9-b04e-4ce0-a6ef-ff629a37ed27.png?tr=n-full_size' ? 'https://images.goodseeker.com/goodseeker/mediafiles/company/1602/story-image/c31a3367-581e-4c48-b12d-507a48830c08.png?tr=n-full_size': story.media[0].fullsize " />
        </div>
        <div data-btn [routerLink]="href" [queryParams]="{public:1}" 
             *ngIf="story.media && story.media.length && story.media[0].type.indexOf('video') > -1"
             class="story-item__video" tabindex="0" [attr.aria-label]="'Watch video for ' + story.title">
          <img (load)="imgOnload($event)" [src]="getUrl(story.media[0])" alt="'Video thumbnail for ' + story.title" />
        </div>
      </div>
      <div class="col-lg-9 col-md-8 align-self-end">
        <div class="story-item__conts">
          <h2 class="story-item__title" id="story-title-{{story.id}}" data-btn 
              [routerLink]="href" [queryParams]="{public:1}" tabindex="0">
            {{story.title}}
          </h2>
          <div class="story-item__info">
            <div class="story-item__user px-0 col-12">
              <gs-avatar [src]="story.author.avatar" isBackground="true" classCss="avatar avatar--xs">
              </gs-avatar>
              <div class="name text-nowrap">{{nameInitials}}</div>
            </div>
          </div>
          <div class="story-item__tags d-lg-block" *ngIf="story.highlightTags">
            <a *ngFor="let tag of story.highlightTags" href="javascript:void(0)" class="tag"
               [style.color]="tag.color">#{{tag.text}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="story-item__detail" (mouseover)="changeHoverColor()"
       (mouseout)="resetHoverColor()" 
       [style.backgroundColor]="dynamicHoverColor" 
       *ngIf="story.media && story.media.length">
    <a [routerLink]="href" [queryParams]="{public:1}" tabindex="0" [attr.aria-label]="'Read more about ' + story.title">
      <div class="story-item__detail-wrap">
        <div class="story-item__detail-desc" *ngIf="story.media && story.media.length">
          <p [innerText]="story.content | stripHtml" role="text"></p>
        </div>
        <div class="story-item__detail-player" *ngIf="story.contributors">
          <div class="player-item" *ngFor="let player of story.contributors">
            <div class="player-item__avatar">
              <gs-avatar [src]="player.avatar" [title]="player.displayName" isBackground="true"
                         classCss="avatar avatar--md">
              </gs-avatar>
            </div>
          </div>
        </div>
        <div class="story-item__detail-more">
          <a class="btn btn-white btn-md btn-outline" tabindex="0" [attr.aria-label]="'Read more about ' + story.title">Read More</a>
        </div>
      </div>
    </a>
  </div>
</div>