export interface CustomUrlViewModel {
    label: string;
    url: string;
}

export interface CustomUrlsViewModel {
    private: CustomUrlViewModel[],
    public: CustomUrlViewModel[]
}

export interface AuthorViewModel {
    authorId: number;
    displayName: string;
    title: string;
    avatar: string;
}

export interface SocialSiteViewModel {
    name: string;
    image: string;
    url: string;
}

export interface FrequentTagItemViewModel {
    color: string,
    data: string[],
    sort: number,
    title: string,
    is_header_part: boolean;
    header_title?:any
}

export interface FrequentTagViewModel {
    [key: number]: FrequentTagItemViewModel
}

export interface CompanyButtonViewModel {
    label: string;
    url: string;
    foreground: string;
    background: string;
    isSelected: boolean;
}

export interface Domain {
    domain: string;
}

export interface CompanyViewModel {
    id: number;
    name: string;
    logo: string;
    email: string;
    domains: Domain[];
    website: string;
    background: string;
    buttons: CompanyButtonViewModel[];
    blurb: string;
    slogan: string;
    author: AuthorViewModel;
    socialSites: SocialSiteViewModel[];
    countStories: number;
    countAuthors: number;
    countContributors: number;
    countCampaigns: number;
    countFeaturedPages: number;
    slug: string;
    pagePublic: boolean;
    isInboundStoriesEnabled: boolean;
    frequentTags: FrequentTagViewModel;
    isCampaignCompany: boolean;
    showLogoHeader: boolean;
    enable_public?: boolean,
    enable_tags?:boolean
    is_logo_updated?: boolean;
    default_story_prompt: any;
    company_sendgrid_details: any;
    internal_stories_approval: any;
    enable_overlay?: boolean;
    show_goodseeker_branding?: boolean;
}

export class CompanyViewModel {
    constructor() { }
}
