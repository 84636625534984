

  <ng-container *ngIf="isBrowser">
    <div [ngClass]="{'site-container': !isIframeMode}">

      <app-external-header></app-external-header>
      <ng-container *ngIf="showHeaderboolean">
        <co-pb-lp-banner [showSubmitsttorybutton]="landingPage?.show_submit_story_btn"></co-pb-lp-banner>
      </ng-container>
      <co-pub-landing-toolbar></co-pub-landing-toolbar>
      <ng-container *ngIf="showtagsboolean && enable_tags">
        <div class="tag_container remove_margin" style="width: 90%;" *ngIf="frequenttags">
          <div class="d-flex justify-content-around mx-7 mobile-view contents hide-board_tags campaignboardstyle" style="margin-left: 5rem !important;">
            <div class="px-2 mb-3 mb-md-0" *ngIf="showorhide(ourteams)">
              <div class="m-infor-boxes text-center text-lg-left">
                <div class="m-infor-boxes__title" *ngIf="frequenttags">{{getNames(ourteams)}}</div>
                <div class="m-infor-boxes__content">
                  <div class="l-chips l-chips--impact">
                    <ng-container *ngIf="frequenttags">
                      <div class="l-chips__item" *ngFor="let tag of frequenttags[ourteams]?.data">
                        <a style="cursor: default;" class="chip-item"
                          [ngStyle]="{'background': hexToRgbA(frequenttags[ourteams]?.color) ,'color':frequenttags[ourteams]?.color}">{{tag}}</a>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!frequenttags">
                      <div class="l-chips__item" *ngFor="let tag of counter">
                        <a  class="onboarding__chips chip-item"></a>
                      </div>
                    </ng-container>
                  </div>
                  <div class="l-chips text-center text-gray" ng-cloak
                    *ngIf="!frequenttags || !frequenttags[ourteams] || frequenttags[ourteams]?.data.length === 0">
                    Stories reveal trending skills and talents here.
                  </div>
                </div>
              </div>
            </div>
            <div class="px-2 mb-3 mb-md-0" *ngIf="showorhide(weAppreciate)">
              <div class="m-infor-boxes text-center text-lg-left">
                <div class="m-infor-boxes__title" *ngIf="frequenttags">{{getNames(weAppreciate)}}</div>
                <div class="m-infor-boxes__content">
                  <div class="l-chips l-chips--impact">
                    <ng-container *ngIf="frequenttags">
                      <div class="l-chips__item" *ngFor="let tag of frequenttags[weAppreciate]?.data">
                        <a style="cursor: default;" class="chip-item"
                          [ngStyle]="{'background': hexToRgbA(frequenttags[weAppreciate]?.color) ,'color':frequenttags[weAppreciate]?.color}">{{tag}}</a>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!frequenttags">
                      <div class="l-chips__item" *ngFor="let tag of counter">
                        <a  class="onboarding__chips chip-item"></a>
                      </div>
                    </ng-container>
                  </div>
                  <div class="l-chips text-center text-gray" ng-cloak
                    *ngIf="!frequenttags || !frequenttags[weAppreciate] || frequenttags[weAppreciate]?.data.length === 0">
                    Stories reveal trending skills and talents here.
                  </div>
                </div>
              </div>
            </div>
            <div class="px-2 mb-3 mb-md-0" *ngIf="showorhide(ourVibes)">
              <div class="m-infor-boxes text-center text-lg-left">
                <div class="m-infor-boxes__title" *ngIf="frequenttags">{{getNames(ourVibes)}}</div>
                <div class="m-infor-boxes__content">
                  <div class="l-chips l-chips--purple" >
                    <ng-container *ngIf="frequenttags">
                      <div class="l-chips__item" *ngFor="let tag of frequenttags[ourVibes]?.data">
                        <a style="cursor: default;" class="chip-item"
                          [ngStyle]="{'background': hexToRgbA(frequenttags[ourVibes]?.color) , 'color': frequenttags[ourVibes]?.color}">{{tag}}</a>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!frequenttags">
                      <div class="l-chips__item" *ngFor="let tag of counter">
                        <a  class="onboarding__chips chip-item"></a>
                      </div>
                    </ng-container>
                  </div>
                  <div class="l-chips text-center text-gray" ng-cloak
                    *ngIf="!frequenttags || !frequenttags[ourVibes] || frequenttags[ourVibes]?.data.length === 0">
                    How your crew is feeling on display here.
                  </div>
                </div>
              </div>
            </div>
            <div class="px-2 mb-3 mb-md-0" *ngIf="showorhide(weValue)">
              <div class="m-infor-boxes text-center text-lg-left">
                <div class="m-infor-boxes__title" *ngIf="frequenttags">{{getNames(weValue)}}</div>
                <div class="m-infor-boxes__content">
                  <div class="l-chips l-chips--green">
                    <ng-container *ngIf="frequenttags">
                      <div class="l-chips__item" *ngFor="let tag of frequenttags[weValue]?.data">
                        <a style="cursor: default;" class="chip-item"
                          [ngStyle]="{'background': hexToRgbA(frequenttags[weValue]?.color) , 'color':frequenttags[weValue]?.color}">{{tag}}</a>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!frequenttags">
                      <div class="l-chips__item" *ngFor="let tag of counter">
                        <a  class="onboarding__chips chip-item"></a>
                      </div>
                    </ng-container>
                  </div>
                  <div class="l-chips text-center text-gray" ng-cloak
                    *ngIf="!frequenttags || !frequenttags[weValue] || frequenttags[weValue]?.data.length === 0">
                    The latest values in action featured here.
                  </div>
                </div>
              </div>
            </div>
            <div class="px-2 mb-3 mb-md-0" *ngIf="showorhide(ourChallenges)">
              <div class="m-infor-boxes text-center text-lg-left">
                <div class="m-infor-boxes__title" *ngIf="frequenttags">{{frequenttags[ourChallenges]?.header_title}}</div>
                <div class="m-infor-boxes__content">
                  <div class="l-chips l-chips--green">
                    <ng-container *ngIf="frequenttags">
                      <div class="l-chips__item" *ngFor="let tag of frequenttags[ourChallenges]?.data">
                        <a style="cursor: default;" class="chip-item"
                          [ngStyle]="{'background': hexToRgbA(frequenttags[ourChallenges]?.color) , 'color':frequenttags[ourChallenges]?.color}">{{tag}}</a>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!frequenttags">
                      <div class="l-chips__item" *ngFor="let tag of counter">
                        <a  class="onboarding__chips chip-item"></a>
                      </div>
                    </ng-container>
                  </div>
                  <div class="l-chips text-center text-gray" ng-cloak
                    *ngIf="!frequenttags || !frequenttags[ourChallenges] || frequenttags[ourChallenges]?.data.length === 0">
                    The latest values in action featured here.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="search_box__hidden_mobile">
        <co-pb-lp-search-box></co-pb-lp-search-box>
      </div>
    
      <co-pb-lp-result></co-pb-lp-result>
      <ng-container *ngIf="view_type == 'normal'">
        <div class="cta-box" [ngClass]="{'is-active': isShowToggleActive, 'is-bottom': isBottom, 'is-first': isFirst}"  *ngIf="isShowNudge">
          <div class="cta">
            <div>
              <div class="cta__inner">
                <div class="cta__header">
                  <button class="btn btn-trans" (click)="toggleShowActive()" [hidden]="ctaStepActivated !== 0">
                    <svg role="img" class="text-pink">
                      <use xlink:href="#icon-collapse"></use>
                    </svg>
                  </button>
                  <button class="btn btn-trans" (click)="closeStep()" [hidden]="ctaStepActivated === 0">
                    <svg role="img" class="text-pink">
                      <use xlink:href="#icon-close"></use>
                    </svg>
                  </button>
                </div>
                <div class="cta__body" [formGroup]="ctaForm">
                  <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 0}">
                    <div class="cta-step__avatar" *ngIf="landingPage && landingPage.avatarImage">
                      <figure>
                        <gs-avatar classCss="avatar" isBackground="true"
                          [src]="landingPage.avatarImage"></gs-avatar>
                      <figcaption *ngIf="landingPage && landingPage.person">{{landingPage.person}}</figcaption>
                      </figure>
                    </div>
                    <div class="cta-step__content text-center text-md-left">
                      <div class="cta-step__text" *ngIf="landingPage.cta.show_author_information" [innerHtml]="landingPage.cta.text | safeHtml" [ngStyle]="{'color':text_color}"></div>
                      <ng-container *ngIf="showJobslist.length">
                        <div class="jobs-list">
                          <div class="jobs-style" *ngFor="let item of showJobslist"><a   [href]="item.url" target="_blank">{{item.title}}</a><span class="addresss" *ngIf="item?.city || item?.state"> - {{item?.city}} <span *ngIf="item?.city && item?.state">,</span> {{item?.state}}</span></div>
                        </div>
                      </ng-container>
                      <div *ngIf="isShowNudgeButton" class="cta-step__more text-center text-md-right">
                        <a *ngIf="isNudgeBasic && landingPage.cta.show_author_information" (click)="addCtaHit(landingPage.cta.link)"   [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" target="_blank"
                          class="btn btn-pink min-width anim-pulse-cta">{{showbbuttontext}}</a>
                        <button *ngIf="isNudgeComplex" type="button"  [ngStyle]="{'background-color':button_color,'color':button_text_color, 'border-color': button_color}" (click)="openStep1()"
                          class="btn btn-pink min-width anim-pulse-cta">{{landingPage.cta.conditional_button_text}}</button>
                          <button *ngIf="isNudgeDonation" type="button"  [ngStyle]="{'background-color':button_color,'color':button_text_color, 'border-color': button_color}" (click)="showDonation()"
                          class="btn btn-pink min-width anim-pulse-cta">{{landingPage.cta.donate_button_text}}</button>
                      </div>
                    </div>
                  </div>
                  <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 1}">
                    <div class="cta-step__avatar" *ngIf="landingPage && landingPage.avatarImage">
                      <figure>
                        <gs-avatar classCss="avatar" isBackground="true"
                        [src]="landingPage.avatarImage"></gs-avatar>
                      <figcaption *ngIf="landingPage && landingPage.person">{{landingPage.person}}</figcaption>
                      </figure>
                    </div>
                    <div class="cta-step__content text-center text-md-left">
                      <h5 class="mb-3">{{landingPage?.cta?.form_title  ?  landingPage?.cta?.form_title  : 'What are you interested in?'}}</h5>
                      <div class="form-group" *ngFor="let item of landingPage?.cta_form_configs; index as i">
                        <ng-container *ngIf="item.is_enabled">
                          <div class="custom-control custom-radio"
                          [ngClass]="{'invalid': !ctaForm.controls.interested.valid && ctaForm.controls.interested.dirty}">
                          <label [for]="'customRadio' + i" class="mb-0">
                            <input [id]="'customRadio' + i" [value]="item.title" formControlName="interested" type="radio"
                              class="custom-control-input" />
                            <span class="custom-control-label">{{item.title}}</span>
                          </label>
                        </div>
                        </ng-container>
          
                      </div>
                      <button (click)="openStep2()" class="btn btn-pink min-width">Continue</button>
                    </div>
                  </div>
                  <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 2}">
                    <div class="cta-step__avatar" *ngIf="landingPage && landingPage.avatarImage">
                      <figure>
                        <gs-avatar classCss="avatar" isBackground="true"
                        [src]="landingPage.avatarImage"></gs-avatar>
                      <figcaption *ngIf="landingPage && landingPage.person">{{landingPage.person}}</figcaption>
                      </figure>
                    </div>
                    <form [formGroup]="ctaForm" (ngSubmit)="ctaFormSubmit()" class="cta-step__content text-center text-md-left">
                      <h5 class="mb-3">How can we get in touch with you?</h5>
                      <div class="form-group">
                        <input truncateString type="text" placeholder="Full name" class="form-control" formControlName="fullName"
                          [ngClass]="{'is-invalid': !ctaForm.controls.fullName.valid && ctaForm.controls.fullName.dirty}"
                          [valueTruncate]="ctaForm.controls.fullname?.value" (valueTruncateChange)="ctaForm.controls.fullname?.setValue($event)" />
                      </div>
                      <div class="form-group">
                        <input type="email" placeholder="Email" class="form-control" formControlName="email"
                          [ngClass]="{'is-invalid': !ctaForm.controls.email.valid && ctaForm.controls.email.dirty}" />
                      </div>
                      <button type="submit" class="btn btn-pink min-width">Continue</button>
                    </form>
                  </div>
                  <div class="cta-step text-center text-md-left" [ngClass]="{'none': ctaStepActivated !== 3}"
                    *ngIf="interestSelected">
                    <div class="cta-step__avatar">
                      <div class="cta-step__avatar" *ngIf="landingPage && landingPage.avatarImage">
                        <figure>
                          <gs-avatar classCss="avatar" isBackground="true"
                          [src]="landingPage.avatarImage"></gs-avatar>
                        <figcaption *ngIf="landingPage && landingPage.person">{{landingPage.person}}</figcaption>
                        </figure>
                    </div>
                    <!--Has interestSelected-->
                    <div class="cta-step__content text-center text-md-left" *ngIf="interestSelected.is_redirection && interestSelected.link">
                      <div class="form-group">
                        <a [href]="interestSelected.link" class="btn btn-pink min-width"
                          target="_blank">{{interestSelected.text}}</a>
                      </div>
                      <div class="text-smaller text-gray">
                        By clicking this link, you will be leaving GoodSeeker.com.
                        <br>
                        Security and privacy policies may be different than ours.</div>
                    </div>
                    <!--Don't Has interestSelected-->
                    <div class="cta-step__content text-center text-md-left" *ngIf="!(interestSelected.is_redirection && interestSelected.link)">
                      <h5 class="mb-3">
                        {{interestSelected.thank_you_text}}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <ng-container *ngIf="(isNudgeDonation && landingPage.cta.show_author_information ) || (isNudgeBasic && landingPage.cta.show_author_information) ||  (isNudgeComplex && landingPage.cta.show_author_information) || (isNudgeJob && landingPage.cta.show_author_information)">
              <div class="cta__button" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="toggleShowActive()">
                <h6>{{landingPage.cta.button}}</h6>
                <button class="btn btn-trans ml-3" style="color: #fff">
                  <svg role="img">
                    <use xlink:href="#icon-expand"></use>
                  </svg>
                </button>
              </div>
            </ng-container>
        
            <ng-container *ngIf="(isNudgeDonation &&  !landingPage.cta.show_author_information)">
              <div class="cta__button"  style="color: white" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="showDonation()">
                <h6>{{landingPage.cta.button}}</h6>
                <!-- <button class="btn btn-trans ml-3">
                  <svg role="img">
                    <use xlink:href="#icon-expand"></use>
                  </svg>
                </button> -->
              </div>
            </ng-container>
            
      
            <ng-container *ngIf="(isNudgeComplex &&  !landingPage.cta.show_author_information)">
              <div class="cta__button" style="color: white" [ngClass]="{'sidebar_mobile_button':isMobile}"  [ngStyle]="{'background-color':button_color,'color':button_text_color, 'border-color': button_color}" (click)="openComplexForm()">
                <h6>{{landingPage.cta.button}}</h6>
                <button class="btn btn-trans ml-3" style="color: #fff">
                  <svg role="img">
                    <use xlink:href="#icon-expand"></use>
                  </svg>
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="(isNudgeBasic &&  !landingPage.cta.show_author_information)">
              <div class="cta__button" style="color: white"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="addCtaHit(landingPage.cta.link)">
                <h6>{{landingPage.cta.button}}</h6>
                <!-- <button class="btn btn-trans ml-3">
                  <svg role="img">
                    <use xlink:href="#icon-expand"></use>
                  </svg>
                </button> -->
              </div>
            </ng-container>
      
            <ng-container *ngIf="(isNudgeJob &&  !landingPage.cta.show_author_information)">
              <div class="cta__button" style="color: white"  [ngStyle]="{'background-color':button_color,'color':button_text_color, 'border-color': button_color}" (click)="toggleShowActive()">
                <h6>{{landingPage.cta.button}}</h6>
                <button class="btn btn-trans ml-3" style="color: #fff">
                  <svg role="img">
                    <use xlink:href="#icon-expand"></use>
                  </svg>
                </button>
              </div>
            </ng-container>
        
          </div>
        </div>
      </ng-container>
      
      <ng-container *ngIf="view_type == 'floating'">
        <div class="cta-box" [ngClass]="{'is-active': isShowToggleActive, 'is-bottom': isBottom, 'is-first': isFirst}"  *ngIf="isShowNudge">
          <div class="cta floating_body" [ngClass]="{'floating_mobile_body':isMobile}" >
            <div>
              <div class="cta__inner">
                <div class="cta__header">
                  <button class="btn btn-trans" (click)="toggleShowActive()" [hidden]="ctaStepActivated !== 0">
                    <svg role="img" class="text-pink">
                      <use xlink:href="#icon-collapse"></use>
                    </svg>
                  </button>
                  <button class="btn btn-trans" (click)="closeStep()" [hidden]="ctaStepActivated === 0">
                    <svg role="img" class="text-pink">
                      <use xlink:href="#icon-close"></use>
                    </svg>
                  </button>
                </div>
                <div class="cta__body" [formGroup]="ctaForm">
                  <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 0}">
                    <div class="cta-step__avatar" *ngIf="landingPage && landingPage.avatarImage">
                      <figure>
                        <gs-avatar classCss="avatar" isBackground="true"
                          [src]="landingPage.avatarImage"></gs-avatar>
                        <figcaption *ngIf="landingPage && landingPage.person">{{landingPage.person}}</figcaption>
                      </figure>
                    </div>
                    <div class="cta-step__content text-center text-md-left">
                      <div class="cta-step__text"  *ngIf="landingPage.cta.show_author_information" [innerHtml]="landingPage.cta.text | safeHtml" [ngStyle]="{'color':text_color}"></div>
                      <ng-container *ngIf="showJobslist.length">
                        <div class="jobs-list">
                          <div class="jobs-style" *ngFor="let item of showJobslist"><a  [href]="item.url" target="_blank">{{item.title}}</a><span class="addresss" *ngIf="item?.city || item?.state"> - {{item?.city}} <span *ngIf="item?.city && item?.state">,</span> {{item?.state}}</span></div>
                        </div>
                      </ng-container>
                      <div *ngIf="isShowNudgeButton" class="cta-step__more text-center text-md-right">
                        <a *ngIf="isNudgeBasic && landingPage.cta.show_author_information" (click)="addCtaHit(landingPage.cta.link)"   [ngStyle]="{'background-color':button_color,  'color':button_text_color, 'border-color': button_color}" target="_blank"
                          class="btn btn-pink min-width anim-pulse-cta">{{showbbuttontext}}</a>
                        <button *ngIf="isNudgeComplex" type="button" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="openStep1()"
                          class="btn btn-pink min-width anim-pulse-cta">{{landingPage.cta.conditional_button_text}}</button>
                          <button *ngIf="isNudgeDonation" type="button" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="showDonation()"
                          class="btn btn-pink min-width anim-pulse-cta">{{landingPage.cta.donate_button_text}}</button>
      
                      </div>
                    </div>
                  </div>
                  <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 1}">
                    <div class="cta-step__avatar" *ngIf="landingPage && landingPage.avatarImage">
                      <figure>
                        <gs-avatar classCss="avatar" isBackground="true"
                          [src]="landingPage.avatarImage"></gs-avatar>
                        <figcaption *ngIf="landingPage && landingPage.person">{{landingPage.person}}</figcaption>
                      </figure>
                    </div>
                    <div class="cta-step__content text-center text-md-left">
                      <h5 class="mb-3">{{landingPage?.cta?.form_title  ?  landingPage?.cta?.form_title  : 'What are you interested in?'}}</h5>
                      <div class="form-group" *ngFor="let item of landingPage?.cta_form_configs; index as i">
                        <ng-container *ngIf="item.is_enabled">
                          <div class="custom-control custom-radio"
                          [ngClass]="{'invalid': !ctaForm.controls.interested.valid && ctaForm.controls.interested.dirty}">
                          <label [for]="'customRadio' + i" class="mb-0">
                            <input [id]="'customRadio' + i" [value]="item.title" formControlName="interested" type="radio"
                              class="custom-control-input" />
                            <span class="custom-control-label">{{item.title}}</span>
                          </label>
                        </div>
                        </ng-container>
          
                      </div>
                      <button (click)="openStep2()" class="btn btn-pink min-width">Continue</button>
                    </div>
                  </div>
                  <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 2}">
                    <div class="cta-step__avatar" *ngIf="landingPage && landingPage.avatarImage">
                      <figure>
                        <gs-avatar classCss="avatar" isBackground="true"
                          [src]="landingPage.avatarImage"></gs-avatar>
                        <figcaption *ngIf="landingPage && landingPage.person">{{landingPage.person}}</figcaption>
                      </figure>
                    </div>
                    <form [formGroup]="ctaForm" (ngSubmit)="ctaFormSubmit()" class="cta-step__content text-center text-md-left">
                      <h5 class="mb-3">How can we get in touch with you?</h5>
                      <div class="form-group">
                        <input truncateString type="text" placeholder="Full name" class="form-control" formControlName="fullName"
                          [ngClass]="{'is-invalid': !ctaForm.controls.fullName.valid && ctaForm.controls.fullName.dirty}"
                          [valueTruncate]="ctaForm.controls.fullname?.value" (valueTruncateChange)="ctaForm.controls.fullname?.setValue($event)" />
                      </div>
                      <div class="form-group">
                        <input type="email" placeholder="Email" class="form-control" formControlName="email"
                          [ngClass]="{'is-invalid': !ctaForm.controls.email.valid && ctaForm.controls.email.dirty}" />
                      </div>
                      <button type="submit" class="btn btn-pink min-width">Continue</button>
                    </form>
                  </div>
                  <div class="cta-step text-center text-md-left" [ngClass]="{'none': ctaStepActivated !== 3}"
                    *ngIf="interestSelected">
                    <div class="cta-step__avatar" *ngIf="landingPage && landingPage.avatarImage">
                      <figure>
                        <gs-avatar classCss="avatar" isBackground="true"
                          [src]="landingPage.avatarImage"></gs-avatar>
                        <figcaption *ngIf="landingPage && landingPage.person">{{landingPage.person}}</figcaption>
                      </figure>
                    </div>
                    <!--Has interestSelected-->
                    <div class="cta-step__content text-center text-md-left" *ngIf="interestSelected.is_redirection && interestSelected.link">
                      <div class="form-group">
                        <a [href]="interestSelected.link" class="btn btn-pink min-width"
                          target="_blank">{{interestSelected.text}}</a>
                      </div>
                      <div class="text-smaller text-gray">
                        By clicking this link, you will be leaving GoodSeeker.com.
                        <br>
                        Security and privacy policies may be different than ours.</div>
                    </div>
                    <!--Don't Has interestSelected-->
                    <div class="cta-step__content text-center text-md-left" *ngIf="!(interestSelected.is_redirection && interestSelected.link)">
                      <h5 class="mb-3">
                        {{interestSelected.thank_you_text}}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
        
          </div>
        </div>
        <ng-container *ngIf="(isNudgeDonation && landingPage.cta.show_author_information ) || (isNudgeBasic && landingPage.cta.show_author_information) ||  (isNudgeComplex &&  landingPage.cta.show_author_information) || (isNudgeJob &&  landingPage.cta.show_author_information)">
          <div class="cta__button floating_button"   [ngClass]="{'floating_mobile_button':isMobile}" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="toggleShowActive()">
            <h6>{{landingPage.cta.button}}</h6>
            <button class="btn btn-trans ml-3" style="color: #fff">
              <svg role="img">
                <use xlink:href="#icon-expand"></use>
              </svg>
            </button>
          </div>
        </ng-container>
      
        <ng-container *ngIf="(isNudgeDonation &&  !landingPage.cta.show_author_information)">
          <div class="cta__button floating_button" style="color: white" [ngClass]="{'floating_mobile_button':isMobile}"   [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="showDonation()">
            <h6>{{landingPage.cta.button}}</h6>
            <!-- <button class="btn btn-trans ml-3">
              <svg role="img">
                <use xlink:href="#icon-expand"></use>
              </svg>
            </button> -->
          </div>
        </ng-container>
      
        <ng-container *ngIf="(isNudgeBasic &&  !landingPage.cta.show_author_information)">
          <div class="cta__button floating_button" style="color: white" [ngClass]="{'sidebar_mobile_button':isMobile}"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="addCtaHit(landingPage.cta.link)">
            <h6>{{landingPage.cta.button}}</h6>
            <!-- <button class="btn btn-trans ml-3">
              <svg role="img">
                <use xlink:href="#icon-expand"></use>
              </svg>
            </button> -->
          </div>
        </ng-container>
      
        <ng-container *ngIf="(isNudgeJob &&  !landingPage.cta.show_author_information)">
          <div class="cta__button floating_button" style="color: white" [ngClass]="{'sidebar_mobile_button':isMobile}"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="toggleShowActive()">
            <h6>{{landingPage.cta.button}}</h6>
            <button class="btn btn-trans ml-3">
              <svg role="img">
                <use xlink:href="#icon-expand"></use>
              </svg>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="(isNudgeComplex &&  !landingPage.cta.show_author_information)">
          <div class="cta__button floating_button" style="color: white" [ngClass]="{'sidebar_mobile_button':isMobile}"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="openComplexForm()">
            <h6>{{landingPage.cta.button}}</h6>
            <button class="btn btn-trans ml-3">
              <svg role="img">
                <use xlink:href="#icon-expand"></use>
              </svg>
            </button>
          </div>
        </ng-container>
      </ng-container>

    
    </div>
    <div class="" (click)="closeDonor()" [ngClass]="{'background':showDonor}">
    </div>
    <div id="donation_form" (click)="closeDonor()" [ngClass]="{'donor__iframe_container':showDonor && !isMobile , 'donor__iframe_container__mobile': isMobile && showDonor}">
    </div>
    <ng-container *ngIf="showFooter">
      <app-site-footer></app-site-footer>
    </ng-container>

    
  </ng-container>

