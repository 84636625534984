<div class="modal-header pb-0">
  <ng-container *ngIf="!initialState.consent">
    <h4 class="modal-title">{{initialState.inbound ? 'Story Display Settings' : 'Story Display Settings'}}</h4>
  </ng-container>
  <ng-container *ngIf="initialState.consent">
    <h4 class="modal-title">{{'Consent'}}</h4>
  </ng-container>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body guest" [formGroup]="copyForm"   *ngIf="initialState.consent || initialState.inbound">

  <ng-container *ngIf="initialState.consent">
    <div class="row mb-4">
      <div class="col-12 mb-1"><span class="headlines">Title:</span> {{story_details?.title}}</div>
      <div class="col-12 mb-1"><span class="headlines">Author:</span> {{story_details?.author?.display_name}}</div>
      <div class="col-12 mb-1"><span class="headlines">Date Published:</span> {{story_details?.published_at | date}}</div>
    </div>
    <div class="template_html mb-5" *ngIf="consent_details?.consent_template">
      <div [innerHtml]="consent_details?.consent_template"></div>
    </div>
  <div class="option_body">
    <h4>Consent Given</h4>
  <h6 *ngIf="consent_details?.from_name">
      Use of Name 
  </h6>
  <div class="row mb-4" *ngIf="consent_details?.from_name">
    <div class="col-12" *ngIf="consent_details?.from_name == 'is_real_name'">
      <span>Full Name can be used</span>
  </div>
  <div class="col-12" *ngIf="consent_details?.from_name == 'is_first_name'">
    <span>First Name only can be used</span>
  </div>
  <div class="col-12" *ngIf="consent_details?.from_name == 'is_initials_name'">
    <span>Initials only can be used</span>
  </div>
  <div class="col-12" *ngIf="consent_details?.from_name == 'is_fake_name'">
    <span>Fake First Name only</span>
  </div>
  </div>
  <h6 *ngIf="consent_details?.from_image">
      Use of Image or Likeness
  </h6>
  <div class="row mb-4" *ngIf="consent_details?.from_image">
   <!-- <div class="col-12"  *ngIf="consent_details?.from_image == 'is_full_image'">
      <span >I allow my image or likeness in all media</span>
  </div>-->
  <div class="col-12" *ngIf="consent_details?.from_image == 'is_image_in_any_media'">
    <span>I allow my image or likeness in all media</span>
  </div>
  <!--<div class="col-12" *ngIf="consent_details?.from_image == 'is_video_use'">
    <span >I allow my image or likeness in video only</span>
  </div>
  <div class="col-12" *ngIf="consent_details?.from_image == 'is_image_social_media'">
    <span >I allow my image or likeness in social media only</span>
  </div>-->
  <div class="col-12"  *ngIf="consent_details?.from_image == 'is_blurred_image'">
    <span >My image or likeness must be blurred or hidden</span>
  </div>
  <div class="col-12" *ngIf="consent_details?.from_image == 'not_use_image'">
    <span >Do not use my image or likeness</span>
  </div>
  </div>
  <h6 *ngIf="consent_details?.from_voice"> 
      Use of Voice 
  </h6>
  <div class="row mb-4" *ngIf="consent_details?.from_voice">
    <div class="col-12" *ngIf="consent_details?.from_voice == 'is_real_voice'">
      <span >My voice can be used</span>
  </div>
  <div class="col-12" *ngIf="consent_details?.from_voice == 'is_masked_voice'">
    <span >My voice must be masked or adjusted</span>
  </div>
  <div class="col-12" *ngIf="consent_details?.from_voice == 'is_voice_transcription'">
    <span >Do not use my voice, but transcription is allowed.</span>
  </div>
  </div>
  
  <h6 *ngIf="consent_details?.from_other">
    Other Uses 
  </h6>
  <div class="row mb-4" *ngIf="consent_details?.from_other">
  <div class="col-12" *ngIf="consent_details?.from_other == 'is_name_advertising'">
    <span >I allow my name to be used in advertising</span>
  </div>
  <div class="col-12" *ngIf="consent_details?.from_other == 'is_image_media_advertising'">
    <span >I allow my image or likeness to be used in advertising</span>
  </div>
  </div>
  <div class="sign_container mb-4" *ngIf="consent_details?.esignature">
      <h4>Signature</h4>
      
      <img *ngIf="isValidUrlRegex" src="{{consent_details?.esignature}}" alt="" srcset="" />
      <div *ngIf="!isValidUrlRegex">{{consent_details?.esignature}}</div>
      <div class="signed_on mt-4 mb-4" *ngIf="consent_details?.signed_on">

        Signed: {{consent_details?.signed_on | date}}
  
      </div>
      <div class="signed_on mt-4 mb-4" *ngIf="!consent_details?.signed_on">
  
      {{'26 Ooctober 2023'}}
  
      </div>
    </div>



  

    </div>
  </ng-container>

  <ng-container *ngIf="!initialState.consent">
    <div class="row" *ngIf="show_toggle">
      <div class="col">
        <label class="row mb-0 spacer">
          <div class="col-auto">
            <div class="form-group mb-4">
              <div class="form-group"><h6>Display Prompt</h6>
              <span class="switch">
                <input type="checkbox" formControlName="show_prompt">
                <span class="slider" [style.background]="copyForm.controls.show_prompt.value ? toggleColor : ''" ></span>
              </span>
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>

  </ng-container>


      <!-- <h6>Show Story Prompt</h6> -->


  <ng-container *ngIf="!initialState.consent">
    <h6>Author Label</h6>
    <div class="row">
    
      <div class="col-10" [ngClass]="{'noPointer':(!copyForm.controls.show_relationship_public.value && initialState.inbound)}">
        <gs-select-material label="Select" name="Label" formControlName="selctedId"
        [formControl]="copyForm.controls.selctedId" (change)="changeData($event)" [data]="testimonoialData" valueField="id" displayField="name">
      </gs-select-material>
      </div>

      <div class="col-1 px-0 px-sm-2 text-center align_item" [ngClass]="{'noPointer':(!copyForm.controls.show_relationship_public.value && initialState.inbound), 'hide_visibility':(hideVisiblity)}"  *ngIf="initialState.inbound">
      <div class="custom-control custom-checkbox" style="bottom:0px">
        <input id="allowrelationsshipPublic" type="checkbox" formControlName="allowrelationsshipPublic" class="custom-control-input">
        <label for="allowrelationsshipPublic" class="custom-control-label limit"></label>
    </div>
      </div>

      <div class="col-1 px-0 px-sm-2 text-center align_item" [ngClass]="{'hide_visibility':(hideVisiblity)}" *ngIf="initialState.inbound">
        <div class="form-group mb-2">
          <div class="text-small">
          </div>
          <div class="custom-control custom-checkbox">
            <input id="show_relationship_public" type="checkbox" (change)="changeActionEvent($event)" formControlName="show_relationship_public"
                class="custom-control-input">
            <label for="show_relationship_public" class="custom-control-label"></label>
        </div>
        </div>
      </div>
    
      <div class="col-9">
        <gs-input-material label="Enter New Label" name="Label" placeholder="Create New Label" formControlName="Label" [formControl]="copyForm.controls.Label" type="text"></gs-input-material>
        <!-- <div class="danger-text" *ngIf="showError">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p class="px-2">{{showError}}</p>
        </div> -->
      </div>
    
      <div class="col-3">
        <button *ngIf="primary_color" type="button" class="headerCopyRequest" style="margin-top: .5rem" [disabled]="getAuthorRelationShip()" (click)="savelabel()">
          Apply
          </button>
      </div>
    
    </div>

    <!-- <h6>Show Story Prompt</h6> -->

    
    <!-- <h6 *ngIf="initialState.inbound"> Company name </h6> -->
    <div  *ngIf="initialState.inbound" class="row">
      <div class="col-10">
        <gs-input-material label="{{copyForm.controls.show_company_public.value ? 'Company name' : '' }}" name="Company name" placeholder="Company Name" formControlName="companyname" [formControl]="copyForm.controls.companyname" type="text"></gs-input-material>
      </div>

      <div class="col-1 px-0 px-sm-2 text-center align_item noPointer" [ngClass]="{'hide_visibility':(hideVisiblity)}">
        <div class="custom-control custom-checkbox" style="bottom:3px">
          <input id="allowShowComapnyName" type="checkbox" formControlName="allowShowComapnyName" class="custom-control-input">
          <label for="allowShowComapnyName" class="custom-control-label limit"></label>
      </div>
      </div>

      <div class="col-1 px-0 px-sm-2 text-center align_item" [ngClass]="{'hide_visibility':(hideVisiblity)}">
        <div class="form-group mb-2">
          <div class="text-small">
          </div>
          <div class="custom-control custom-checkbox">
            <input id="show_company_public" type="checkbox" (change)="changeActionEvent($event)" formControlName="show_company_public"
                class="custom-control-input">
            <label for="show_company_public" class="custom-control-label"></label>
        </div>
        </div>
      </div>
    
    </div>
    
    <!-- <h6  *ngIf="initialState.inbound">Name</h6> -->
    <div  *ngIf="initialState.inbound" class="row">
      <div class="col-10">
        <gs-input-material  label="{{copyForm.controls.show_name_public.value ? 'Name' : '' }}" name="Name" placeholder="Name" formControlName="name" [formControl]="copyForm.controls.name" type="text"></gs-input-material>
      </div>

      <div class="col-1 px-0 px-sm-2 text-center align_item noPointer" [ngClass]="{'hide_visibility':(hideVisiblity)}">
        <div class="custom-control custom-checkbox" style="bottom:3px">
          <input id="allowShowName" type="checkbox" formControlName="allowShowName" class="custom-control-input">
          <label for="allowShowName" class="custom-control-label limit"></label>
      </div>
      </div>

      <div class="col-1 px-0 px-sm-2 text-center align_item" [ngClass]="{'hide_visibility':(hideVisiblity)}">
        <div class="form-group mb-2">

          <div class="custom-control custom-checkbox">
            <input id="show_name_public" type="checkbox" (change)="changeActionEvent($event)" formControlName="show_name_public"
                class="custom-control-input">
            <label for="show_name_public" class="custom-control-label"></label>
        </div>
        </div>
      </div>
    
    </div>
    <!-- <h6  *ngIf="initialState.inbound"> Job Title </h6> -->
    <div  *ngIf="initialState.inbound" class="row">
      <div class="col-10">
        <gs-input-material  label="{{copyForm.controls.show_title_public.value ? 'Job Title' : '' }}"  name="Job Title" placeholder="Title" formControlName="title" [formControl]="copyForm.controls.title" type="text"></gs-input-material>
      </div>

      <div class="col-1 px-0 px-sm-2 text-center align_item noPointer" [ngClass]="{'hide_visibility':(hideVisiblity)}">
        <div class="custom-control custom-checkbox" style="bottom:3px">
          <input id="allowShowTitle" type="checkbox" formControlName="allowShowTitle" class="custom-control-input">
          <label for="allowShowTitle" class="custom-control-label limit"></label>
      </div>
        
      </div>

      <div class="col-1 px-0 px-sm-2 text-center align_item" [ngClass]="{'hide_visibility':(hideVisiblity)}">
        <div class="form-group mb-2">
          <div class="custom-control custom-checkbox">
            <input id="show_title_public" type="checkbox" (change)="changeActionEvent($event)" formControlName="show_title_public"
                class="custom-control-input">
            <label for="show_title_public" class="custom-control-label"></label>
        </div>
        </div>
      </div>
    
    </div>

    <div class="row" *ngIf="initialState.inbound">
      <div class="col upload_guest_user_photo">
        <div class="update_photo">
          <div class="personal-settings-photo mb-4" style="display: block;">
            <div class="personal-settings-photo__thumbnail mb-3">
              <div class="setting-photo-upload ml-5">
                <div [hidden]="uploaderViewModel.isUploading" class="setting-photo-upload__image"
                  [ngClass]="{'background-transparent':copyForm.controls.avatarImage.valid}">
                  <img *ngIf="copyForm.controls.avatarImage.value" class="avatars"
                    [src]="(copyForm.controls.avatarImage.value) | safe: 'resourceUrl'" alt="">
                </div>
                <div [hidden]="!uploaderViewModel.isUploading" class="setting-photo-upload__counter">
                  {{uploaderViewModel.progressCss}}</div>
                <div [hidden]="!uploaderViewModel.isUploading" [style.height]="uploaderViewModel.progressCss"
                  class="setting-photo-upload__progress"></div>
              </div>
            </div>
            <div class="personal-settings-photo__content">
  
              <div class="">
                <button style="height: 2.5rem; width: 14rem;" *ngIf="primary_color" [style.background]="primary_color ? primary_color : ''" class="btn btn-pink btn-design" id="add-message-btn"
                  (click)="uploadImage()"> Upload Photo </button>
              </div>
  
              <div class="text-danger text-small" *ngIf="uploaderViewModel.isError">{{uploaderViewModel.errorMsg}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </ng-container>
 




</div>

<!---  -->

<div class="modal-body guest" [formGroup]="userForm"   *ngIf="!initialState.consent && !initialState.inbound">

  <ng-container *ngIf="!initialState.consent">
    <div class="row" *ngIf="show_toggle">
      <div class="col">
        <label class="row mb-0 spacer">
          <div class="col-auto">
            <div class="form-group mb-4">
              <div class="form-group"><h6>Display Prompt</h6>
              <span class="switch">
                <input type="checkbox" formControlName="show_prompt">
                <span class="slider" [style.background]="userForm.controls.show_prompt.value ? toggleColor : ''" ></span>
              </span>
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>

  </ng-container>


      <!-- <h6>Show Story Prompt</h6> -->


  <ng-container *ngIf="!initialState.consent">
    <h6>Author Label</h6>
    <div class="row">
    
      <div class="col-10">
        <gs-select-material label="Select" name="Label" formControlName="selctedId"
        [formControl]="userForm.controls.selctedId" (change)="changeData($event)" [data]="testimonoialData" valueField="id" displayField="name">
      </gs-select-material>
      </div>

    
      <div class="col-9">
        <gs-input-material label="Enter New Label" name="Label" placeholder="Create New Label" formControlName="Label" [formControl]="userForm.controls.Label" type="text"></gs-input-material>
        <!-- <div class="danger-text" *ngIf="showError">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p class="px-2">{{showError}}</p>
        </div> -->
      </div>
    
      <div class="col-3">
        <button *ngIf="primary_color" type="button" class="headerCopyRequest" style="margin-top: .5rem" [disabled]="getAuthorRelationShip()" (click)="savelabel()">
          Apply
          </button>
      </div>
    
    </div>

    <!-- <h6>Show Story Prompt</h6> -->

    
    <!-- <h6 *ngIf="initialState.inbound"> Company name </h6> -->

    
    <!-- <h6  *ngIf="initialState.inbound">Name</h6> -->
    <div   class="row">
      <div class="col-10">
        <gs-input-material  label="Name" name="Name" placeholder="Name" formControlName="name" [formControl]="userForm.controls.name" type="text"></gs-input-material>
      </div>    
    </div>
    <!-- <h6  *ngIf="initialState.inbound"> Job Title </h6> -->
    <div  class="row">
      <div class="col-10">
        <gs-input-material  label="Title"  name="Title" placeholder="Title" formControlName="title" [formControl]="userForm.controls.title" type="text"></gs-input-material>
      </div>

    
    </div>

    <div class="row">
      <div class="col upload_guest_user_photo">
        <div class="update_photo">
          <div class="personal-settings-photo mb-4" style="display: block;">
            <div class="personal-settings-photo__thumbnail mb-3">
              <div class="setting-photo-upload ml-5">
                <div [hidden]="uploaderViewModel.isUploading" class="setting-photo-upload__image"
                  [ngClass]="{'background-transparent':userForm.controls.avatarImage.valid}">
                  <img *ngIf="userForm.controls.avatarImage.value" class="avatars"
                    [src]="(userForm.controls.avatarImage.value) | safe: 'resourceUrl'" alt="">
                </div>
                <div [hidden]="!uploaderViewModel.isUploading" class="setting-photo-upload__counter">
                  {{uploaderViewModel.progressCss}}</div>
                <div [hidden]="!uploaderViewModel.isUploading" [style.height]="uploaderViewModel.progressCss"
                  class="setting-photo-upload__progress"></div>
              </div>
            </div>
            <div class="personal-settings-photo__content">
  
              <div class="">
                <button style="height: 2.5rem; width: 14rem;" *ngIf="primary_color" [style.background]="primary_color ? primary_color : ''" class="btn btn-pink btn-design" id="add-message-btn"
                  (click)="uploadImage()"> Upload Photo </button>
              </div>
  
              <div class="text-danger text-small" *ngIf="uploaderViewModel.isError">{{uploaderViewModel.errorMsg}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </ng-container>
 




</div>

<div class="modal-footer"  *ngIf="!initialState.consent && initialState.inbound">
  <div class="button-right">

      <button type="button" data-dismiss="modal" class="btn btn-outline btn-gray-light min-width"
        (click)="close()">Cancel</button>

      <button *ngIf="primary_color" [style.background]="primary_color ? primary_color : ''"  type="button" class="btn btn-pink min-width" (click)="save()" [disabled]="!copyForm.valid">
        {{initialState.inbound ? 'Save' : 'Save'}}
      </button>



  </div>


</div>
<div class="modal-footer"  *ngIf="!initialState.consent && !initialState.inbound">
  <div class="button-right">

      <button type="button" data-dismiss="modal" class="btn btn-outline btn-gray-light min-width"
        (click)="close()">Cancel</button>

      <button *ngIf="primary_color" [style.background]="primary_color ? primary_color : ''"  type="button" class="btn btn-pink min-width" (click)="savealias()" [disabled]="!copyForm.valid">
        {{initialState.inbound ? 'Save' : 'Save'}}
      </button>



  </div>


</div>

<!-- <iframe src="http://localhost:4200/?&showHeader=true&showtagheader=true" height="900px" width="100px" frameborder="0"></iframe> -->