import { Injectable } from '@angular/core';
import { s3 } from 'fine-uploader/lib/core/s3';
import { interval, noop } from 'rxjs';

import { UploaderConfig, UploadedFileInfoModel } from '../models/uploader-models';
import { Callback } from '@framework/models/uploader-models/callback.model';
import { environment } from '@app/env/environment';
import { UtilityHelper } from '@framework/utilities';
import { AuthService } from './auth.service';
import { from, throwError,Subscription,Observable } from 'rxjs';
import { switchMap, catchError, takeWhile, map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http';
@Injectable()
export class UploaderService {
    private s3KeyPrefix: string;// = `mediafiles/company`;
    private path: string;
    private token: string;
    private uploadedFiles: UploadedFileInfoModel[] = [];

    private httpClient: HttpClient

    constructor(private utility: UtilityHelper, private authService: AuthService,private http:HttpClient) {
        this.authService.cognito.getIdToken().then((token: string) => {
            this.token = token;
        });
    }

    // Private functions
    private buildPath(config: UploaderConfig) {
        this.path = `mediafiles/company/${config.companyId}`;
        this.s3KeyPrefix = `${environment.aws.s3.path}/${this.path}`;
    }

    private onUploadAllComplete() {
        // Clear uploaded files list
        this.uploadedFiles = [];
    }

    private addFileExtensionFilter(config: UploaderConfig) {
        if (config.button && config.validate.allowedExtensions && config.validate.allowedExtensions.length > 0) {
            const acceptExtensions = config.validate.allowedExtensions.map(extension => `.${extension}`);
            let fileInputControl = config.button.querySelector('input[type=file]');
            fileInputControl.setAttribute('accept', acceptExtensions.join(','));
            config.button.addEventListener('change', () => {
                setTimeout(() => {
                    fileInputControl = config.button.querySelector('input[type=file]');
                    fileInputControl.setAttribute('accept', acceptExtensions.join(','));
                }, 1000)
            });
        }
    }
    // Public functions
    public s3Uploader(config: UploaderConfig, callbacks: Callback) {
        this.buildPath(config);
        let signature: any = {};
        if (config.isAnonymous) {
            signature.endpoint = `${environment.endpointUrl}/users/s3-anonymous-signature/`;
        }
        else {
            signature.endpoint = `${environment.endpointUrl}/users/s3-signature/`;
            signature.customHeaders = {
                "Authorization": `Bearer ${this.token}`
            }
        }

        const uploader = new s3.FineUploaderBasic({
            button: config.button,
            debug: false,
            autoUpload: config.autoUpload,
            multiple: config.multiple,
            validation: {
                allowedExtensions: config.validate.allowedExtensions,
                sizeLimit: config.validate.sizeLimit,
                image: {
                    minHeight: config.validate.imageMinHeight || 0,
                    minWidth: config.validate.imageMinWidth || 0
                }
            },
            messages: {
                minHeightImageError: config.messages.imageDimensionError,
                minWidthImageError: config.messages.imageDimensionError,
                sizeError: config.messages.maxSizeError,
                typeError: config.messages.typeError
            },
            region: environment.aws.s3.region,
            request: {
                endpoint: environment.aws.s3.processedEnpoint,
                accessKey: environment.aws.accessId
            },
            signature: signature,
            cors: {
                expected: true,
                sendCredentials: true
            },
            objectProperties: {
                acl: 'public-read',
                key: (id) => {
                    const uuid = uploader.getUuid(id);
                    const fileInfo = uploader.getFile(id);
                    const fileext = fileInfo.name && fileInfo.name.indexOf('.') > -1 ? fileInfo.name.split('.').pop() : fileInfo.type.split('/').pop();
                    const filename = `${uuid}.${fileext}`;
                     //console.log("ffileinfor",fileInfo)
                     if(!fileInfo?.path){
                        if(fileInfo.type.includes('video')){
                            fileInfo.path = 'story-video'
                        }
                        else{
                            fileInfo.path = 'story-image'
                        }
                     }
 
                    this.uploadedFiles.push({
                        name: filename,
                        type: fileInfo.type,
                        path: `/mediafiles/company/${config.companyId}/${fileInfo.path}`,
                        id: id,
                        meta:fileInfo.meta
                    });
                    uploader.setEndpoint(environment.aws.s3.processedEnpoint);
                    return `${this.s3KeyPrefix}/${fileInfo.path}/${filename}`
                }
                
            },
            // scaling: {
            //     sizes: [
            //         { name: 'medium', maxSize: 300 }
            //     ]
            // },
            callbacks: {
                onSubmit: callbacks.onSubmit || noop,
                onSubmitted: callbacks.onSubmitted || noop,
                onComplete: callbacks.onComplete || noop,
                onAllComplete: (successful, failed) => {
                    this.onUploadAllComplete();
                    if (callbacks.onAllComplete)
                        callbacks.onAllComplete(successful, failed)
                },
                onCancel: callbacks.onCancel || noop,
                onUpload: callbacks.onUpload || noop,
                onUploadChunk: callbacks.onUploadChunk || noop,
                onUploadChunkSuccess: callbacks.onUploadChunkSuccess || noop,
                onResume: callbacks.onResume || noop,
                onProgress: callbacks.onProgress || noop,
                onTotalProgress: callbacks.onTotalProgress || noop,
                onError: callbacks.onError || noop,
                onSessionRequestComplete: callbacks.onSessionRequestComplete || noop,
                onValidate: callbacks.onValidate || noop,
                onValidateBatch: callbacks.onValidateBatch || noop
            }
        });

        // Work arround 
        this.addFileExtensionFilter(config);

        return uploader;
    }

    public getUploadedFile(id: number) {
        const file = this.uploadedFiles.find(f => f.id === id);
        return file;
    }
    // Properties
    get Path() {
        return `/${this.path}`;
    }

   
     secondsToHHMMSS(seconds: number): string {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
    
        const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    
}