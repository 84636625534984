import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

import { ComponentBase } from '@framework/base/component/component.base';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { CoreState } from '@app/store/core/states';
import { ImageCloudinaryPipe } from '@modules/shared/pipes';
import { LoadStatusModel } from '@modules/shared/models';
import { SearchStoryInputApiModel } from '@modules/story/models/api-models/input';
import { StoryViewModel } from '@modules/story/models/view-models';
import { storySelector } from '@modules/story/store/selectors';
import { GsMasonryComponent } from '@modules/shared/components/layout/gs-masonry/gs-masonry.component';
import { searchBoxActions } from '@modules/company/store/actions';
import { companySelectors } from '@modules/company/store/selectors';
import { storyStepActions } from '@modules/story/store/actions';
import { storyActions } from '@modules/company-admin/store/actions';

@Component({
  selector: 'co-pu-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent extends ComponentBase {

  @ViewChild(GsMasonryComponent)
  gsMasonryComponent: GsMasonryComponent;
  public showIndicator: boolean = false;
  public disabled: boolean = false;
  public userLables:any;
  public GuestLables:any;
  public user_label_text:any;
  public guest_label_text:any;

  // Private variables
  private selectStories$: Observable<StoryViewModel[]>;
  private selectLoadStoryStatus$: Observable<LoadStatusModel>;

  private selectStoriesSubscription: Subscription;
  private selectLoadStoryStatusSubscription: Subscription;
  private resetPageSubscription: Subscription;
  private loadColorSuccessSubscription: Subscription;

  public queryStoriesData: SearchStoryInputApiModel = new SearchStoryInputApiModel();
  // Public variables
  public stories: StoryViewModel[] = [];
  public isViewList: boolean = false;
  public loadStoryStatus: LoadStatusModel;
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  private selectCurrentCompany$: Observable<CompanyViewModel>;
  private selectCurrentCompanySubscription: Subscription;
  private loadStepSuccessSubscription: Subscription;
  public showSortByDatePublished: boolean = false;
  public showSortByRecentActivity: boolean = true;
  public stepsData: any;

  constructor(private actions$: Actions, private store$: Store<CoreState>, public imageCloudinaryPipe: ImageCloudinaryPipe) {
    super();
    this.queryStoriesData.pagination.sort = 'desc';
    this.selectStories$ = this.store$.select(storySelector.selectAll);
    this.selectLoadStoryStatus$ = this.store$.select(storySelector.selectLoadStatus);
    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {
    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((currentCompany) => {
      if (currentCompany) {
        this.currentCompany = currentCompany;
        this.store$.dispatch(new storyStepActions.Load(this.currentCompany.id));
        this.store$.dispatch(new storyActions.GetColor(this.currentCompany?.id));
      }
    });

    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.GetColorSuccess>(storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.userLables = action.response.find(item => item.button == 1).color;
        this.GuestLables = action.response.find(item => item.button == 2).color;
        this.user_label_text = action.response.find(item => item.button == 18).color;
        this.guest_label_text = action.response.find(item => item.button == 19).color;
        // this.boxshadowcolr = '0 -0.1875rem 0'+action.response.find(item => item.button == 6).color+'inset;'
       }
      

    })

    this.loadStepSuccessSubscription = this.actions$.pipe(
      ofType<storyStepActions.LoadSuccess>(storyStepActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((result) => {
      if (result.data) {
        this.stepsData = result.data;
      }
    });

    this.selectStoriesSubscription = this.selectStories$.subscribe((stories) => {
      this.stories = stories || [];
      this.showIndicator = false;
      setTimeout(() => {
        this.reloadMansonry();
      }, 1000);
    });

    this.resetPageSubscription = this.actions$.pipe(
      ofType<searchBoxActions.ResetPage>(searchBoxActions.ActionTypes.RESET_PAGE)
    ).subscribe((action) => {
      this.queryStoriesData.pagination.page = 1;
      
    });

    this.selectLoadStoryStatusSubscription = this.selectLoadStoryStatus$.subscribe((loadStoryStatus) => {
      this.loadStoryStatus = loadStoryStatus;
      if (loadStoryStatus) {
        if (loadStoryStatus.isLoaded && loadStoryStatus.isCanLoadMore) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      }
    });
  }

  private unsubscribe() {
    this.selectStoriesSubscription.unsubscribe();
    this.selectLoadStoryStatusSubscription.unsubscribe();
    this.selectCurrentCompanySubscription.unsubscribe();
    this.loadStepSuccessSubscription.unsubscribe();
    this.resetPageSubscription.unsubscribe();
    this.loadColorSuccessSubscription.unsubscribe();
    }

  private reloadMansonry() {
    this.gsMasonryComponent.reloadLayout();
  }

  private isShowSortByDatePublished() {
    return this.queryStoriesData.pagination.sortBy === 'time_updated';
  }

  private isShowSortByRecentActivity() {
    return this.queryStoriesData.pagination.sortBy === 'published_at';
  }

  // Public functions
  public trackByFunc(story: StoryViewModel) {
    return story.id;
  }

  public changeViewType() {
    this.isViewList = !this.isViewList;
    this.reloadMansonry();
  }

  public changeSortType() {
    if (this.queryStoriesData.pagination.sort === 'desc') {
      this.sort('asc');
    } else {
      this.sort('desc');
    }
  }

  private sort(sortOrder: string) {
    this.queryStoriesData.pagination.sort = sortOrder;
    this.queryStoriesData.pagination.page = 1;
    this.store$.dispatch(new searchBoxActions.Search(
      {
        pagination: this.queryStoriesData.pagination
      }
    ));
  }

  public showSearchBox() {
    this.store$.dispatch(new searchBoxActions.Show());
  }

  public loadMoreStories() {
    if (this.loadStoryStatus.isLoaded && this.loadStoryStatus.isCanLoadMore) {
      this.showIndicator = true;
      this.queryStoriesData.pagination.page += 1;
      this.store$.dispatch(new searchBoxActions.Search(
        {
          pagination: this.queryStoriesData.pagination
        }
      ));
    }
  }

  componentRendered() {
    setTimeout(() => {
      this.reloadMansonry();
    }, 1000);
  }
}
