import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { UpdateProfilePublicInputApiModel } from '../models/api-models/input';
import { ProfilePublicOutputApiModel } from '../models/api-models/output';


@Injectable()
export class ProfilePublicHttpService extends BaseHttpService {

  constructor(httpClient: HttpClient) {
    super(httpClient, '/companies');
  }

  public loadProfile(companyId: number) {
    return this.get(`/${companyId}/setting/profile/public/`).pipe(
      map((response) => {
        //console.log(response)
        return new ProfilePublicOutputApiModel(response);
      })
    )
  }

  public changeSlug(companyId: number, payload: Partial<UpdateProfilePublicInputApiModel>) {
    return this.put(`/${companyId}/setting/profile/public/slug/`, { slug: payload.slug }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public updateProfile(companyId: number, payload: Partial<UpdateProfilePublicInputApiModel>) {
    return this.patch(`/${companyId}/setting/profile/public/`, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }



}
