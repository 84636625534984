import { StoryEntity } from '../../store/entities';
import { Constants } from '@modules/shared/constants';
import { ApprovalStatusEnum } from '@modules/shared/enums';

export interface StoriesAuthorViewModel {
    authorId: number;
    avatar: string;
    displayName: string;
    title: string;
    display_title:any;
    relationship: string;
    companyName: string;
    slug: string;
    companySlug: string;
    companyWebsite: string;
}

export interface StoryMediaViewModel {
    fullsize?: string;
    id?: number;
    preview?: string;
    type?: string;
    saveIn?: number;
    source?: any;
    thumbnail_url?:any;
    asset_id?:any;
    aspect_ratio?:any
    status?:any;
}

export interface StoryCtaViewModel {
    text: string;
    button: string;
    config: any;
    link: string;
    type: string;
    cta_slug?:any;
    donate_button_content?: any;
    donate_button_script?: any;
    donate_button_text?: any;
    cta_id_company_cta?: any;
    show_author_information?: boolean;
    show_jobs?:boolean;
    form_title?:any;
    conditional_button_text?: any;
}

export interface StoryCompanyViewModel {
    companyId: number;
    name: string;
    website: string;
    slug: string;
    logo: string;
}

export interface StoryTagViewModel {
    label: string;
    color: string;
}

export interface ContributionDataViewModel {
    message: string;
    tags: StoryTagViewModel[];
}

export interface StoryContributionViewModel {
    id: number;
    avatar: string;
    displayName: string;
    playerId: number;
    data: ContributionDataViewModel;
    likeId: number;
    slug: string;
}

export class StoryContributionViewModel {
    constructor(data?: Partial<StoryContributionViewModel>) {
        if (data)
            Object.assign(this, data);
    }

    public get isLiked(): boolean {
        return this.likeId > 0;
    }
}

export interface HighlightTagsViewModel {
    text: string;
    color: string;
    sort: number;
}

export interface StoryListContributorViewModel {
    playerId: number;
    displayName: string;
    avatar: string;
}

export interface DataItemApiModel {
    idOrSlug: number | string;
    label: string;
}

export interface StepDataItemViewModel {
    title: string;
    type: string;
    color: string;
    sort: number;
    data: DataItemApiModel[];
}

export interface StepDataViewModel {
    [key: number]: StepDataItemViewModel;
}

export interface CtaFormConfigViewModel {
    title: string;
    link: string;
    text: string;
    isRedirection: boolean;
    isRequiredInfo: boolean;
    thankYouText: string;
    is_enabled?:boolean;
}

export interface StoryViewModel {
    isNamePromotable: number;
    id: number;
    title: string;
    total_count: any;
    slug: string;
    content: string;
    contentRaw: string;
    campaignId: number;
    media?: StoryMediaViewModel[];
    companyIdSlug: string;
    public: boolean;
    internal?: boolean;
    approval: ApprovalStatusEnum;
    approved: boolean;
    completed: boolean;
    createdAt: Date;
    createdBy: number;
    publishedAt: Date;
    order?:number
    publishedBy: number;
    stepsData: StepDataViewModel;
    steps: any;
    cta: StoryCtaViewModel;
    likesCount: number;
    viewsCount: number;
    commentsCount: number;
    author: StoriesAuthorViewModel;
    authorInboundType: string;
    likeId: number;
    followId: number;
    id_campaign: string;
    campaign_id: string;

    company: StoryCompanyViewModel;
    status: number;
    type: string;
    contributions: StoryContributionViewModel[];
    highlightTags: HighlightTagsViewModel[];
    contributors: StoryListContributorViewModel[];
    ctaFormConfigs: CtaFormConfigViewModel[];

    publicRequested: boolean;
    isNoPermission: boolean;
    uniqueId: string;
    internalProfilePublic: boolean;
    externalProfilePublic: boolean;
    externalAnonymize: boolean;
    ctaInteractionsCount: number;
    next_story_slug: string
    prev_story_slug: string
    archived: boolean;
    tagsCountData: TagsCountData[];
    requested_by:any;
    story_prompt: any;
    published_to_draft: any;
    deleted?:any;
    question_id_campaign?:any;
    video_token?:any;
    is_footnote_included?:boolean
    interview_content?:any
    interview_style?:boolean;
    default_prompt?:any;
    show_prompt?:boolean;
    show_relationship_public?:boolean;
    show_company_public?: boolean;
    show_name_public?: boolean;
    show_title_public?: boolean;
    consent_details?:any;
    approval_flow?:boolean
    final_approved?:boolean;
    author_alias:any;

}

export interface TagsCountData {
    count: number;
    tagId: number;
}

export class StoryViewModel {
    constructor(data?: StoryEntity) {
        if (data)
            this.convertFromStore(data);
    }

    private convertFromStore(data: StoryEntity) {
        Object.assign(this, data);
        this.checkApprovalStatus();
    }

    public get isLiked(): boolean {
        return this.likeId > 0;
    }

    public get isFollowed(): boolean {
        return this.followId > 0;
    }

    public get isTestimonial(): boolean {
        return this.type === Constants.storyType.inbound;
    }

    public checkApprovalStatus() {
        if (!this.public) {
            this.approval = ApprovalStatusEnum.Internal;
        } else if (this.public && this.approved) {
            this.approval = ApprovalStatusEnum.Public;
        } else if (this.public && !this.approved) {
            this.approval = ApprovalStatusEnum.Pending;
        }
    }
}
