import { Component, HostListener, Inject, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ofType, Actions } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from '@app/env/environment';
import * as _ from 'lodash';
import { Page } from '@framework/base/page';
import { UtilityHelper } from '@framework/utilities';
import { CoreState } from '@app/store/core/states';
import { storyActions } from '@modules/story/store/actions';
import { storySelector } from '@modules/story/store/selectors';
import { StepDataItemViewModel, StoryViewModel } from '@modules/story/models/view-models';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { companySelectors } from '@modules/company/store/selectors';
import { companyActions, landingPageActions } from '@modules/company/store/actions';
import * as adminStoryActions from '@modules/company-admin/store/actions';
import { GsTokenModel } from '@modules/shared/models/common';
import { Constants } from '@modules/shared/constants';
import { StoryLoadStatusViewModel } from '@modules/story/models/store-view-models';
import { StoryOutputApiModel } from '@modules/story/models/api-models/output';
import { SeoService } from '@modules/shared/services/seo.service';
import { MetaTagConfig } from '@modules/shared/models/common/seo.model';
import { AuthService, LoadingPopupService } from '@framework/services';
import { campaignActions } from '@modules/company/store/actions';
import { CampaignViewModel } from '@modules/company/models/store-view-models';
import { campaignSelectors } from '@modules/company/store/selectors';
import { ApprovalmodalComponent, CopyEmbedVideoModalContainerComponent, CopyLinkContainerComponent, CopyVideoModalContainerComponent, EmailConfirmationModalComponent, ShareModalContainerComponent } from '@modules/shared/components';
import { GetCampaignInputApiModel } from '@modules/company/models/api-models/input';
import { PaginationModel } from '@framework/models/pagination-models';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalService, ToastService } from '@framework/services';
import { ScriptActions } from '@modules/company-admin/store/actions';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { StoryHttpService } from '@modules/story/services';
import * as Sservice  from '@modules/company-admin/store/services';
declare const gtag: Function;
declare var dataLayer;
declare var $: any;
declare var window:any;

let measuremnetId;
let pagename;
@Component({
  selector: 'app-donate-page',
  templateUrl: './donate-button-script.component.html',
  styleUrls: ['./donate-button-script.component.scss']
})
export class DonateButtonScriptComponent extends Page {
  // Private variables

  public isBrowser:boolean;
  public srcValues:any[] = [] ;
  private loadDetailSuccess:Subscription;
  private loadSpotlightSuccess: Subscription;
  public donationDiv:any;
  public backgroundColor:any;
  public donationScript:any;
  public showDonor:boolean = true;

  quillModule = {
    toolbar:[{}],
  }

  constructor(
    private utility: UtilityHelper,
    private store$: Store<CoreState>,
    private activatedRoute: ActivatedRoute,
    private actions$: Actions,
    private landingPopupService: LoadingPopupService,
    private router: Router,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) platformId: Object,
    public sanitizer: DomSanitizer,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
    // this.checkIsAuthenticated();
    this.isBrowser = isPlatformBrowser(platformId);
  }
  isSmallScreen: boolean = window.innerWidth <= 895;
  @HostListener('window:resize', ['$event'])
  onResize(event: any){
    this.isSmallScreen = window.innerWidth <= 895;
  }
  // Life cycle hook
  protected onInit(): void {
  
    this.subscribe();
    this.activatedRoute.queryParams.subscribe((res) => {
      //console.log('fromEmbed',res);
      if (res && res.id) {
        this.store$.dispatch(new storyActions.LoadDetail(res.id,null,true));
        this.backgroundColor = atob(res.cls)
        // this.queeryTrackingId = res.tr;
        // this.addtrackhit(obj)
      }
      if(res && res.sid){
        this.store$.dispatch(new landingPageActions.LoadDetail(res.cid, res.sid));
      }
      // if (res && res.fromEmbed) {
      //   this.isEmbed = true;
      // }
    })
  }
  protected onDestroy(): void {
    if(this.isBrowser){
      this.unsubscribe();
    }

    this.seoService.clearMetaTags();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {

  }

private unsubscribe() {
  this.loadDetailSuccess.unsubscribe();
}
  

  private subscribe(){
    this.loadDetailSuccess = this.actions$.pipe(
      ofType<storyActions.LoadDetailSuccess>(storyActions.ActionTypes.LOAD_DETAIL_SUCCESS)
    ).subscribe(action => {
     
      if(action && action?.data){
         if(action.data?.cta?.donate_button_content)   {
          this.donationDiv = action.data?.cta?.donate_button_content[0]
         }
         if(action.data?.cta?.donate_button_script)   {
          this.donationScript = action.data?.cta?.donate_button_script[0]
         }
         this.showDonation()
      }



    })

    this.loadSpotlightSuccess = this.actions$.pipe(
      ofType<landingPageActions.LoadDetailSuccess>(landingPageActions.ActionTypes.LOAD_DETAIL_SUCCESS)
    ).subscribe(action => {
       if(action && action.response){
        if(action.response?.cta?.donate_button_content)   {
          this.donationDiv = action.response?.cta?.donate_button_content[0]
         }
         if(action.response?.cta?.donate_button_script)   {
          this.donationScript = action.response?.cta?.donate_button_script[0]
         }
         this.showDonation()
       }
    })
  }

  showDonation(){
    if(this.isBrowser){
      // window.open('https://donorbox.org/treatment-to-poor-child','popup','width=600,height=600')
  
      document.getElementById('donation_form').innerHTML =  this.donationDiv ? this.donationDiv : "";
if(this.donationScript){
  var tagSrcMatches = this.donationScript.match(/<[^>]+src=["']([^"']+)["']/gi);
  if (tagSrcMatches) {
      tagSrcMatches.forEach((match)=> {
          var srcMatch = match.match(/src=["']([^"']+)["']/);
          if (srcMatch && srcMatch[1]) {
              this.srcValues.push(srcMatch[1]);
          }
      });
  }
  var jsSrcMatches = this.donationScript.match(/\.src\s*=\s*["']([^"']+)["']/gi);
  if (jsSrcMatches) {
      jsSrcMatches.forEach((match)=> {
          var srcMatch = match.match(/\.src\s*=\s*["']([^"']+)["']/);
          if (srcMatch && srcMatch[1]) {
            this.srcValues.push(srcMatch[1]);
          }
      });
  }
}

      
      setTimeout(()=>{
        
      //   firstscript.innerText = 'window.Givebutter=window.Givebutter||function(){(Givebutter.q=Givebutter.q||[]).push(arguments)};Givebutter.l=+new Date; window.Givebutter("setOptions", { "accountId": "yb13IdkfCCMx8ek1" });'
      // document.getElementById('donation_form').appendChild(firstscript);
      // let secondScript = document.createElement('script');
      // secondScript.src ='https://js.givebutter.com/elements/latest.js';
      // secondScript.async = true;
      // document.getElementById('donation_form').appendChild(secondScript);
      $('#donation_form').append( this.donationScript ? this.donationScript : "");
      // document.getElementById('donation_form').appendChild(firstscript);
      },250)
    }

    const iframeScrollEvent = setInterval(() => {
      const slackCode = localStorage.getItem('integrationCode');
      var iframe = document.querySelector("iframe");
      if (iframe) {
        iframe.setAttribute("scrolling", "yes");
      }
    }, 200);

  }
  closeDonor(){
    window.close();
    if(this.isBrowser){
      if(this.showDonor){
        this.showDonor = false;
        document.getElementById('donation_form').innerHTML = ''
        if(this.srcValues.length){
          var elements = document.getElementsByTagName('script');
          for (var i = elements.length - 1; i >= 0; i--) {
              if (this.srcValues.includes(elements[i].src)) {
                  elements[i].parentNode.removeChild(elements[i]);
              }
          }
        }

      }
      else{
        return;
      }
    }


  }

}
  /**
   * this method is to update meta tag according to story detail
   */
 


