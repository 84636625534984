import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap, switchMap, filter, map, take } from 'rxjs/operators';

import { CoreState } from '@app/store/core/states';
import { UtilityHelper } from '@framework/utilities';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { userProfileActions } from '@modules/user/store/actions';

@Injectable()
export class UserProfileGuard  {
    constructor(private store$: Store<CoreState>, private utilities: UtilityHelper) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        return new Promise((resolve, reject) => {
            this.getUserProfile().toPromise().then((userProfile) => {
                //console.log(route.queryParams.email);

                if (userProfile) {
                    resolve(true);
                } else {
                    if (route.queryParams.email) {
                        window.location.href = `/user/signup?link=${encodeURI(btoa(state.url))}&u=${encodeURIComponent(btoa(route.queryParams.email))}`;
                    } else {
                        window.location.href = `/user/login?link=${encodeURI(btoa(state.url))}`;
                    }
                    resolve(false);
                }
            }, error => {
                resolve(false);
                if (route.queryParams.email) {
                    //console.log(route.queryParams.email, encodeURI(btoa(route.queryParams.email)));

                    window.location.href = `/user/signup?link=${encodeURI(btoa(state.url))}&u=${encodeURIComponent(btoa(route.queryParams.email))}`;
                } else {
                    window.location.href = `/user/login?link=${encodeURI(btoa(state.url))}`;
                }
            });
        });
    }

    private getUserProfile(): Observable<UserProfileViewModel> {
        let uProfile: UserProfileViewModel;
        return this.store$
            .select(userProfileSelectors.selectCurrentProfile)
            .pipe(
                tap((userProfile: UserProfileViewModel) => {
                    if (!userProfile) {
                        return this.store$.dispatch(new userProfileActions.LoadCurrentProfile());
                    }
                }),
                switchMap((userProfile) => {
                    uProfile = userProfile;
                    return this.store$.select(userProfileSelectors.selectIsLoaded)
                }),
                filter((isLoaded) => isLoaded === true),
                map((userProfileLoadStatus) => uProfile),
                take(1)
            );
    }
}