<div>
  <co-pu-banner-skeleton [hidden]="currentCompany.background"></co-pu-banner-skeleton>
  <div class="banner_style b-timeline" 
       [ngClass]="{'add-overlay': currentCompany.enable_overlay}" 
       [hidden]="!currentCompany.background" 
       [background-image]="currentCompany.background" 
       role="banner">
    <div class="container">
      <div class="container">
        <div class="d-block d-md-inline-flex btn-group mr-5" role="group" aria-label="Company actions">
          <a *ngFor="let button of getButtons()" 
             [style.background-color]="button.background"
             [style.color]="button.foreground" 
             class="btn mobile-view btn-sq btn-white" 
             href="{{button.url}}" 
             target="_blank" 
             role="button" 
             [attr.aria-label]="button.label">
            {{button.label}}
          </a>
          <ng-container *ngIf="!insideIframe">
            <a *ngIf="currentCompany.isInboundStoriesEnabled"
               [style.background]="btnColor"
               [style.color]="textColor"
               [routerLink]="['/t/' + currentCompany.slug + '/testimonial/create']" 
               class="btn btn-sq btn-pink" 
               role="button" 
               aria-label="Submit Story">
              Submit Story
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>